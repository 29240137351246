/**
 * Session module.
 * The Session module handles everything that is session specific, like
 * connection status, language..etc
 * @module connection
 */

"use strict";

import {log, isAndroid, isIos} from './helpers.js';

let connected = false;
export let deviceInfo = {
    'model': 'unknown-model',
    'platform': 'unknown-platform',
    'uuid': 'unknown-uuid',
    'version': 'unknown-version',
    'manufacturer': 'unknown-manufacturer'
};

export let sessionVariables = {};

// List of potential session variables:
// hasProfile
// hasAccount
// locationAccess
// certificateValid
// publicroamNearby
// apiAvailable
// countryCode
// phoneNumber
// phoneNumberHasAccount
// username
// password
// email
// token
// useRealApi

export function initSession(callback) {
    log('Initialize connection manager');

    let sesVars = window.localStorage.getItem('sessionVariables');
    if(sesVars !== null){
        sessionVariables = JSON.parse(sesVars);
    } else {
        setVar('useRealApi', true);
        setVar('certificateValid', true);
    }
    let con = window.localStorage.getItem('connected');
    connected = con !== null && con === 'true';

    if(!window.localStorage.getItem('language')) {
        switch(navigator.language){
            case 'en':
                setLanguage('en');
                break;
            case 'en-US':
                setLanguage('en');
                break;
            default:
                setLanguage('nl');
                break;
        }
    }

    if(window.hasOwnProperty('device')) {
        deviceInfo = {
            'model': window.device.model,
            'platform': window.device.platform,
            'uuid': window.device.uuid,
            'version': window.device.version,
            'manufacturer': window.device.manufacturer,
        };
    }

    return callback();
}

export function refreshLocationPermissions(success, error) {
    if(typeof success === 'undefined') {
        success = function(){};
    }

    if(typeof error === 'undefined') {
        error = function(){};
    }

    if(window.debug && !isAndroid() && !isIos()) {
        if(getVar('locationAccess')){
            return success();
        } else {
            return error();
        }
    }

    if(isAndroid() || isIos()) {
        navigator.geolocation.getCurrentPosition((position) => {
            /*
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const altitude = position.coords.altitude;
            const accuracy = position.coords.accuracy;
            const altitudeAccuracy = position.coords.altitudeAccuracy;
            const heading = position.coords.height;
            const speed = position.coords.speed;
            const timestamp = position.timestamp;
            */
            setVar('locationAccess', true);
            success();
        }, () => {
            setVar('locationAccess', false);
            error();
        }, {
            'enableHighAccuracy': false,
        });
    } else {
        jwpm.setupPermissions(() => {
            setVar('locationAccess', true);
            success();
        }, () => {
            setVar('locationAccess', false);
            error();
        });
    }
}

export function isConnected() {
    return connected;
}

export function setConnected(status) {
   connected = status; 
}

export function getLanguage() {
    return window.localStorage.getItem('language') || 'nl';
}

export function setLanguage(language) {
    return window.localStorage.setItem('language', language);
}

export function setVar(name, value) {
    sessionVariables[name] = value;
    window.localStorage.setItem('sessionVariables', JSON.stringify(sessionVariables));
}
export function deleteVar(name) {
    delete sessionVariables[name];
    window.localStorage.setItem('sessionVariables', JSON.stringify(sessionVariables));
}

export function getVar(name) {
    return sessionVariables[name] || false;
}

export function dumpVars() {
    console.log(sessionVariables);
}

export function deleteAllVars() {
    sessionVariables = {};    
    window.localStorage.setItem('sessionVariables', JSON.stringify(sessionVariables));
    setConnected(false);
}
