/**
 * API module. This module handles all the API calls
 */

"use strict";

import * as Constants from './constants.js';
import * as Helpers from './helpers.js';
import * as Session from './session.js';

/**
 * Create Account API Call. You send a phonenumber and e-mail address and if
 * both check out you receive an SMS on that phone number for further
 * verification.
 * @param phoneNumber
 * @param email
 * @param successCallback the function called by the jQuery post call when the
 *                        API call results in a 20x
 * @param errorCallback the function called by jQuery when the API call fails
 */
export function createAccount(
    phoneNumber,
    email,
    successCallback,
    errorCallback
) {
    let location = 'APP';
    if(Helpers.isAndroid()){
        location = 'APP-A';
    }

    const timestamp = Math.floor(Date.now() / 1000);
    const secretHash = Helpers.sha256(phoneNumber + getSalt() + timestamp);

    // TODO: add encryption fields when that functionality is live

    let jsonBody = {
        'location': location,
        'originator': phoneNumber,
        'email': email,
        'app': true,
        // 'secret_hash': secretHash,
        // 'timestamp': timestamp,
    };

    $.ajax({
        url: Constants.API_URL + 'account/request',
        type:"POST",
        data: JSON.stringify(jsonBody),
        contentType:"application/json; charset=utf-8",
        dataType:"json",
        success: successCallback
    }).fail(errorCallback);
}

/**
 * Verify an account by sending the phonenumber and a pincode.
 */
export function verifyAccount(
    phoneNumber,
    pincode,
    successCallback,
    errorCallback
) {
    let jsonBody = {
       'originator': phoneNumber,
       'pincode': pincode,
    };

    $.ajax({
        url: Constants.API_URL + 'account/verify',
        type:"PUT",
        data: JSON.stringify(jsonBody),
        contentType:"application/json; charset=utf-8",
        dataType:"json",
        success: successCallback
    }).fail(errorCallback);

}


/**
 * Delete a publicroam account using the given phone number
 */
export function deleteAccount(
    phoneNumber,
    successCallback,
    errorCallback
) {
    let jsonBody = {
       'originator': phoneNumber,
       'body': 'AFMELDEN',
    };

    $.ajax({
        url: Constants.API_URL + 'sms/receive',
        type:"POST",
        data: JSON.stringify(jsonBody),
        contentType:"application/json; charset=utf-8",
        dataType:"json",
        success: successCallback
    }).fail(errorCallback);
}


/**
 * Add device tries to add a new device to a given
 * account. A pincode should be sent to the given phone
 * number.
 */
export function addDevice(
    phoneNumber,
    successCallback,
    errorCallback
) {
    let jsonBody = {
       'originator': phoneNumber,
    };

    $.ajax({
        url: Constants.API_URL + 'account/verify',
        type:"POST",
        data: JSON.stringify(jsonBody),
        contentType:"application/json; charset=utf-8",
        dataType:"json",
        success: successCallback
    }).fail(errorCallback);

}


/**
 * Check if the given account exists and is active
 * @param phoneNumber
 * @param username
 * @param password
 * @param successCallback the function called by the jQuery post call when the
 *                        API call results in a 20x
 * @param errorCallback the function called by jQuery when the API call fails
 */
export function accountCheck (
    phoneNumber,
    username,
    password,
    successCallback,
    errorCallback
) {
    let jsonBody = {
        'originator': phoneNumber,
        'username': username,
        'password': password,
    };

    $.ajax({
        url: Constants.API_URL + 'account/check',
        type:"POST",
        data: JSON.stringify(jsonBody),
        contentType:"application/json; charset=utf-8",
        dataType:"json",
        success: successCallback
    }).fail(errorCallback);
}


/**
 * Change e-mail address
 * @param email
 * @param token
 * @param successCallback the function called by the jQuery post call when the
 *                        API call results in a 20x
 * @param errorCallback the function called by jQuery when the API call fails
 */
export function changeEmail (
    email,
    token,
    successCallback,
    errorCallback
) {
    let jsonBody = {
        'email': email,
        'token': token,
    };

    $.ajax({
        url: Constants.API_URL + 'account/email/update',
        type:"POST",
        data: JSON.stringify(jsonBody),
        contentType:"application/json; charset=utf-8",
        dataType:"json",
        success: successCallback
    }).fail(errorCallback);
}

export function sendDeviceInfo(successCallback, errorCallback) {
    let deviceInfo = Session.deviceInfo;
    let fullPhoneNumber = Session.getVar('fullPhoneNumber');
    if(fullPhoneNumber === false) {
        const countryCode = Session.getVar('countryCode');
        const phoneNumber = Session.getVar('phoneNumber');
        if(countryCode === false || phoneNumber === false) {
            return;
        }
        fullPhoneNumber = countryCode + phoneNumber;
    }

    if(fullPhoneNumber.length > 0 && fullPhoneNumber[0] === '+') {
        fullPhoneNumber = fullPhoneNumber.substr(1);
    }

    let jsonBody = {
        'originator': fullPhoneNumber,
        'manufacturer': deviceInfo.manufacturer,
        'type': deviceInfo.model,
        'os': deviceInfo.platform + ' ' + deviceInfo.version,
    };

    $.ajax({
        url: Constants.API_URL + 'account/device/info',
        type:"POST",
        data: JSON.stringify(jsonBody),
        contentType:"application/json; charset=utf-8",
        dataType:"json",
        success: successCallback,
    }).fail(errorCallback);
}

/**
 * Generates the salt used for encrypting API account creation calls
 * @returns {string}
 */
function getSalt() {
    let passwordBase = Constants.SECRET_BASE;
    for(let i = 0; i < 100; i++) {
        passwordBase = Helpers.sha256(passwordBase);
    }
    return sjcl.decrypt(passwordBase, Constants.SALT_ENCRYPTED);
}
