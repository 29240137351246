/**
 * Home view module.
 * @module home
 */

"use strict";

import * as Gui from '../core/gui.js';
import {debugAlert} from '../core/helpers.js';
import * as Session from '../core/session.js';
import * as Middleware from '../core/middleware.js';

export class HomeView {
    constructor() {
        this.viewName = 'HomeView';
    }

    render(event, args) {
        Middleware.sendDeviceInfo();

        if(Session.getVar('hasAccount')) {
            if(!Session.getVar('hasProfile')) {
                // We have an account but no wifi profile, so we have to find
                // out what's the problem. It's possible that there as a
                // pre-existing wifi profile that blocks our configuration
                // attempts.
                window.visit('/#/installing');
            } else {
                Middleware.hasLocationPermissions().then(() => {
                    Middleware.isConnected().then(() => {
                        window.visit('/#/connected');
                    }).catch(() => {
                        window.visit('/#/not-connected');
                    });
                }).catch(() => {
                    Middleware.setupLocationPermissions().then(() => {
                        Session.refreshLocationPermissions();
                    }).catch(() => {
                    });

                    window.visit('/#/not-connected-no-location');
                });

            }
        } else {
            Gui.render('home.html', {
                'hideNetworkStatus': true,
            });
        }
    }

    todo(event, args) {
        debugAlert('Dit is nog niet geïmplementeerd.');
    }
}
