/**
 * Helpers module.
 * @module helpers
 */

"use strict";

/**
 * Checks the type of a given value
 * @param {mixed} type - can be 'string', 'number', or 'boolean'
 * @param {mixed} value - the value to check the type of
 * @returns {boolean} whether the type matches
 */
export function checkType(type, value) {
    switch(type){
        case 'string': {
            return typeof value === 'string';
        }
        case 'number': {
            return typeof value === 'number';
        }
        case 'boolean': {
            return typeof value === 'boolean';
        }
        default: {
            log(`Unrecognized type: ${type}`);
            return false;
        }
    }
}


/**
 * Checks the nodetype of a given event target
 * @param {Event} event - the event of a click
 * @returns {boolean} whether currentTarget is a form
 */
export function eventIsForm(event) {
    return $(event.currentTarget).is('form');
}

/**
 * Checks the nodetype of a given event target
 * @param {Event} event - the event of a click
 * @returns {boolean} whether currentTarget is a link
 */
export function eventIsLink(event) {
    return $(event.currentTarget).is('a');
}

/**
 * Logs a message to the console, only if debugging is enabled
 * @param {string} message - the message to be logged
 */
export function log(message){
    if(window.debug){
        console.log(message);
    }
}

/**
 * Shows a debug alert box, only if debugging is enabled
 * @param {string} message - the message for the alert box
 */
export function debugAlert(message){
    if(window.debug){
        alert('DEBUG MESSAGE: ' + message);
    }
}

/**
 * Shows a debug confirm box, only if debugging is enabled
 * @param {string} message - the message for the alert box
 * @returns {boolean} whether the user clicked "ok"
 */
export function debugConfirm(message){
    return confirm('DEBUG CONFIRMATION: ' + message);
}

/**
 * Create a new event
 * @param {string} eventName
 */
export function createNewEvent(eventName) {
    let event;

    // Polyfill for IE
    if(typeof(Event) === 'function') {
        event = new Event(eventName);
    } else{
        event = document.createEvent('Event');
        event.initEvent(eventName, true, true);
    }
    return event;
}

/**
 * Pad a string with zeros until it becomes given size
 * @param {string} str
 * @param {int} size - the size
 * @returns {string} the padded string
 */
export function padZero(str, size) {
    str = String(str);
    while (str.length < size) {
        str = "0" + str;
    }
    return str;
}

export function openInappBrowser(url){
    if(typeof cordova !== 'undefined' && cordova.InAppBrowser && cordova.InAppBrowser.open){
        cordova.InAppBrowser.open(url, '_blank', 'location=no');
    } else {
        window.open(url, '_blank');
    }
}

/**
 * Try to parse given item as JSON. If it's already an object it returns it
 * unmodified, otherwise it tries to run JSON.parse on it. If that fails it
 * returns false
 * @param data
 * @returns Object or False
 */
export function parseJson(data) {
    if(typeof data === 'object'){
        return data;
    }

    try {
        data = JSON.parse(data);
        return data;
    } catch(err){
        return false;
    }
}

/**
 * Cleans up a given phone number. If country code is empty then +31 is assumed.
 * Returns the unchanged phone number if the number is invalid.
 * @param {string} country code
 * @param {string} phoneNumber
 * @returns string
 */
export function sanitizePhoneNumber(countryCode, phoneNumber) {
    phoneNumber = phoneNumber.replace(/\s/g, '');

    if(countryCode === '+31' && phoneNumber[0] === '0') {
       phoneNumber = phoneNumber.substring(1);
    }

    return phoneNumber;
}

export function isAndroid(){
    return window.hasOwnProperty('device') && window.device.hasOwnProperty('platform') && window.device.platform === 'Android';
}

export function isIos(){
    return window.hasOwnProperty('device') && window.device.hasOwnProperty('platform') && window.device.platform === 'iOS';
}

export function isAndroid10() {
    if(isAndroid() && window.device.hasOwnProperty('version')){
        const version = window.device.version;
        if(version.length >= 2 && version[0] === '1' && version[1] === '0'){
            return true;
        }
    } else {
        return false;
    }
}

export function isAndroidGte11() {
    if(isAndroid() && window.device.hasOwnProperty('version')){
        const version = window.device.version;
        if(version.length >= 2 && version[0] === '1' && version[1] !== '0'){
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export function sha256(str) {
    return sjcl.codec.hex.fromBits(
        sjcl.hash.sha256.hash(str)
    );
}
