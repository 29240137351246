/**
 * Gui module.
 * @module gui
 */

"use strict";

import * as Session from './session.js';
import * as Constants from './constants.js';
import * as Helpers from './helpers.js';

let env;
let connected = false;
let hasLocationPermissions = false;

/**
 * Initialize the Gui system, set up Nunjucks.
 */
export function initGui(){
    env = nunjucks.configure({ autoescape: true });
    initFilters();
    refreshConnectionStatus(true);
    hasLocationPermissions = Session.getVar('locationAccess');

    if(window.debug){
        $('a.debug').show();
    }
}

function translateString(str) {
    return safeString(_(str));
}

/**
 * Initialize Nunjucks filters
 */
function initFilters() {
    env.addFilter('trans', translateString);
}

/**
 * Render a given template with a context using Nunjucks, and put the
 * result in the container div
 * @param {string} template - i.e "home.html"
 * @param {object} context - the context object used for rendering
 */
export function render(template, context={}) {
    context.session = {};
    context.session.language = Session.getLanguage();
    context.session.isAndroid = Helpers.isAndroid();
    context.session.isAndroid10 = Helpers.isAndroid10();
    context.session.isAndroidGte11 = Helpers.isAndroidGte11();
    context.session.isIos = Helpers.isIos();

    if(context.backButton) {
        $('header button.back').show();
    } else {
        $('header button.back').hide();
    }
    
    if(context.hideNetworkStatus) {
        $('section.network-status').hide();
        $('div.container').addClass('status-hidden');
    } else {
        $('section.network-status').show();
        $('div.container').removeClass('status-hidden');
    }

    context.session.connected = connected;

    $('div.container').html(env.render(template, context));

    window.scrollTo(0,0);
}

/**
 * Makes a given string safe for rendering
 * @param {string} str - i.e a rendered template
 * @returns {string} safe string
 */
export function safeString(str) {
    let safeString = new nunjucks.runtime.SafeString(str);
    return safeString;
}

/**
 * Render a given string with a context using Nunjucks, and return
 * the result.
 * @param {string} template - i.e "<p>{{ greeting }} world</p>"
 * @param {object} context - the context object used for rendering, i.e {'greeting': 'Hello'}
 * @returns {string} rendered string
 */
export function renderString(str, context={}) {
    return env.renderString(str, context);
}

/**
 * 404 error page, shown when routing fails
 */
export function show404(){
    render('404.html');
}

export function addMessage(level, message) {
    $('#messages').append(render('message.html', {
        level: level,
        message: message
    }));
}

/**
 * Setup the current menu
 */
export function setupMenu(){
    let context = {};
    context.session = {};
    context.session.language = Session.getLanguage();
    context.session.connected = Session.isConnected();
    context.session.vars = Session.sessionVariables;
    context.version = Constants.VERSION;
    $('section.menu').html(env.render('menu.html', context));
}

/**
 * Refreshes the connection status shown in the header
 */
export function refreshConnectionStatus(force=false){
    if(
        force ||
        connected !== Session.isConnected() ||
        hasLocationPermissions !== Session.getVar('locationAccess')
    ) {
        connected = Session.isConnected();
        hasLocationPermissions = Session.getVar('locationAccess');
        if(hasLocationPermissions && connected){
            $('section.network-status').removeClass('not-connected');
            $('span.connection-status').html('<span class="icon-wifi"></span>&nbsp;' + _('Connected'));
        } else {
            if(
                !hasLocationPermissions &&
                Session.getVar('hasProfile') &&
                (Helpers.isAndroid() || Helpers.isIos())
            ){
                $('section.network-status').removeClass('connected');
                $('section.network-status').addClass('not-connected');
                $('span.connection-status').html('<span class="icon-block"></span>&nbsp;' + _('Wifi status unknown'));
            } else {
                $('section.network-status').removeClass('connected');
                $('section.network-status').addClass('not-connected');
                $('span.connection-status').html('<span class="icon-block"></span>&nbsp;' + _('Not connected to publicroam'));
            }
        }
        setupMenu();
    }
}
