/**
 * Account view module.
 * @module account
 */

"use strict";

import * as Constants from '../core/constants.js';
import * as Gui from '../core/gui.js';
import * as Session from '../core/session.js';
import * as Helpers from '../core/helpers.js';
import * as Middleware from '../core/middleware.js';

export class AccountView {
    constructor() {
        this.viewName = 'AccountView';
    }

    render(event, args, extraContext=false) {
        let expand = false;

        if(args && args.hasOwnProperty('expand')) {
            expand = args.expand;
        }

        let renderAccountPage = (accountValid=true) => {
            let context = {
                'backButton': true,
                'sesVars': Session.sessionVariables,
                'expand': expand,
                'accountValid': accountValid,
            };

            if(extraContext !== false) {
                for(let item in extraContext) {
                    if(!extraContext.hasOwnProperty(item)) {
                        continue;
                    }
                    context[item] = extraContext[item];
                }
            }
            return Gui.render('my-account.html', context);
        };

        if(Session.getVar('hasProfile')) {
            Gui.render('loading.html');
            Middleware.accountCheck().then(() => {
                return renderAccountPage();
            }).catch((error) => {
                if(error === Constants.ERROR_ACCOUNT_NOT_FOUND) {
                    return renderAccountPage(false);
                } else {
                    return renderAccountPage();
                }
                return renderAccountPage();
            });
        } else {
            return renderAccountPage();
        }
    }

    sendDeviceInfo() {
        Middleware.sendDeviceInfo().then(() => {
            this.render(
                Helpers.createNewEvent('device-info'),
                {'expand': 'device-info'},
                {
                    'deviceInfoMessage': _('Your device information has been sent successfully.'),
                }
            );
        }).catch((e) => {
            this.render(
                Helpers.createNewEvent('device-info'),
                {'expand': 'device-info'},
                {
                    'deviceInfoError': _('Your device information could not be sent. Please try again later.'),
                }
            );
        });
    }

    terminateAccount() {
        if(confirm(_('Are you sure you want to terminate your publicroam account?'))) {
            Gui.render('terminating.html');
            Middleware.deleteAccount(Session.getVar('countryCode').replace('+', '') + Session.getVar('phoneNumber')).then(() => {
                Session.setVar('hasAccount', false);
                Session.setVar('hasProfile', false);
                Session.deleteVar('username');
                Session.deleteVar('password');
                Session.deleteVar('email');
                Middleware.terminate();
                window.visit('/#/terminate-account-success');
            }).catch((error) => {
                let errorType = error.type;
                let errorBody = error.body;
                let terminationError = '';
                if(errorType === Constants.ERROR_ACCOUNT_NOT_FOUND){
                    terminationError = Gui.safeString(_('Could not find an account for your phone number. You can register a new account through <a href="/#/register/again">Register again</a>.'));
                } else {
                    // TODO, better error message
                    terminationError = Gui.safeString(_('Something went wrong trying to terminate your account.'));
                }
                return Gui.render('my-account.html', {
                    'backButton': true,
                    'sesVars': Session.sessionVariables,
                    'terminationError': terminationError,
                });
            });
        } else {
            window.visit('/#/my-account');
        }
    }

    terminateAccountSuccess() {
        // TODO: add other cleanup
        Session.setConnected(false);
        Session.deleteVar('hasProfile');
        Session.deleteVar('hasAccount');
        Session.deleteVar('phoneNumber');
        Session.deleteVar('phoneNumberHasAccount');
        Session.deleteVar('username');
        Session.deleteVar('password');
        Session.deleteVar('token');
        return Gui.render('terminate-account-success.html');
    }

    changeEmail(event, args) {
        const email1 = $('input[name=email').val();
        const email2 = $('input[name=email-repeat').val();
        $('form p.error').html('');
        $('form input[name=email]').removeClass('error');
        $('form input[name=email-repeat]').removeClass('error');

        let hasErrors = false;

        if(email1 !== email2){
            $('form p.error').html(_('The e-mail addresses are not the same'));
            $('form input[name=email]').addClass('error');
            $('form input[name=email-repeat]').addClass('error');
            hasErrors = true;
        }

        const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if(!emailRegex.test(String(email1).toLowerCase())){
            $('form p.error').append('The e-mail address is invalid'); 
            $('form input[name=email]').addClass('error');
            $('form input[name=email-repeat]').addClass('error');
            hasErrors = true;
        }

        if(hasErrors) {
            return;
        }

        Middleware.changeEmail(email1).then(() => {
            $('form p.error').html('');
            $('form input[name=email]').removeClass('error');
            $('form input[name=email-repeat]').removeClass('error');
            $('form.change-email').hide();
            $('form.change-email').before('<p>' + _('Your e-mail address was modified successfully.') + '</p>');
            Session.setVar('email', email1);
            $('span.email').html(email1);
        }).catch(() => {
            $('form p.error').html(_('There was a problem changing your e-mail address. Please try again later.'));
        });
    }
}
