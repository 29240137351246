"use strict";

let translations = {
    // src/templates/account-active.html,
    '(No internet connection is required to complete the registration)': '(Om af te ronden heb je geen internetverbinding nodig)',

    // src/templates/join-us.html,
    '<a class="button full-width" rel="external" href="https://publicroam.nl/app/en/register-newsletter">Keep me posted</a>': '<a class="button full-width" rel="external" href="https://publicroam.nl/app/nl/register-newsletter">Houd mij op de hoogte</a>',

    // src/templates/remove-wifi-profile.html,
    '<a rel="external" href="https://publicroam.nl/app/en/support/#veelgestelde-vragen">Look here for an explanation per device</a>': '<a rel="external" href="https://publicroam.nl/app/nl/support/#veelgestelde-vragen">Kijk hier voor uitleg per apparaat</a>',

    // src/templates/intro.html,
    '<b>Automatically online</b><br/> at all connected locations.': '<b>Automatisch online</b><br/> op alle aangesloten locaties.',

    // src/templates/install-final-step.html,
    '<b>Beware</b>: Are you connected to a different WiFi? You will not connect to publicroam.': '<b>Let op</b>: Ben je verbonden met een ander wifi? Dan verbind je niet met publicroam.',

    // src/templates/intro.html,
    '<b>Secure connection</b><br/> via your personal account.': '<b>Een veilige verbinding</b><br/> via je persoonlijke account.',

    // src/templates/intro.html,
    '<b>Your privacy is guaranteed.</b><br/> We don&#39;t share any data; you go online anonymously.': '<b>Je privacy is gewaarborgd.</b><br/> We delen geen gegevens, je surft anoniem.',

    // src/templates/home.html,
    '<span class="red">Sign up</span> your device for publicroam': '<span class="red">Meld je</span> apparaat aan voor publicroam',

    // src/templates/intro.html,
    'A new WiFi experience': 'Een nieuwe wifi-ervaring',

    // src/templates/install-final-step.html,
    'Accept the notification if it appears': 'Accepteer de melding als die verschijnt',

    // src/templates/at-your-organization.html,
    'Add the service to your existing network. It works in combination with all standard WiFi equipment.': 'Je voegt de dienst toe aan je bestaande netwerk. Het werkt in combinatie met alle gangbare wifi-apparatuur.',

    // src/templates/install-final-step.html,
    'Additional action (only on Android 10)': 'Extra handeling (alleen bij Android 10)',

    // src/templates/other-wifi.html,
    'After a little while you will automatically connect to publicroam. If not, in the publicroam app go to Menu &gt; Sign in again': 'Na enige tijd verbind je automatisch met publicroam. Zo niet, ga dan in de publicroam app naar Menu &gt; Opnieuw aanmelden.',

    // src/templates/install-final-step.html,src/templates/support.html,src/templates/menu.html,src/templates/not-connected.html,src/templates/install-success-no-location.html,src/templates/install-success-no-location.html,src/templates/account-active.html,src/templates/not-connected-no-location.html,src/templates/not-connected-no-location.html,
    'All publicroam locations': 'Alle publicroam-locaties',

    // src/templates/install-final-step.html,
    'Allow to connect': 'Geef toestemming om te verbinden',

    // src/templates/menu.html,
    'App version': 'App versie',

    // src/templates/not-connected.html,
    'Are you currently at a publicroam location? Choose <a class="menu" href="#">sign in again</a> to try again.': 'Ben je nu op een publicroam locatie? Kies dan <a class="menu" href="#">opnieuw aanmelden</a> om het nog een keer te proberen.',

    // src/templates/install-final-step.html,
    'Are you not receiving this notification? Check the extra step.': 'Krijg je deze melding niet? Kijk bij Extra stap hieronder.',

    // src/templates/remove-wifi-profile.html,
    'Are you stuck?': 'Kom je er niet uit?',

    // src/src/views/account.js,
    'Are you sure you want to terminate your publicroam account?': 'Weet je zeker dat je je publicroam-account wil beëindigen?',

    // src/templates/home.html,
    'Automatically on WiFi. Wherever publicroam is. Secure, easy, private.': 'Automatisch op wifi. Op steeds meer plekken. Veilig, makkelijk en privé.',

    // src/templates/change-phonenumber.html,
    'Change phonenumber': 'Telefoonnummer wijzigen',

    // src/templates/install-final-step.html,
    'Choose &apos;yes&apos;': 'Kies &apos;ja&apos;',

    // src/templates/install-final-step.html,
    'Choose <b>Connections</b>': 'Kies <b>Verbindingen</b>',

    // src/templates/install-final-step.html,
    'Choose <b>WiFi</b>': 'Kies <b>Wi-Fi</b>',

    // src/templates/install-final-step.html,
    'Choose <b>Yes</b>': 'Kies <b>Ja</b>',

    // src/templates/other-wifi.html,
    'Choose Connections or Network': 'Kies Verbindingen of Netwerk',

    // src/templates/other-wifi.html,
    'Choose Disconnect or Forget': 'Kies Verbreek verbinding of Vergeten',

    // src/templates/remove-wifi-profile.html,
    'Choose remove network or forget': 'Kies netwerk verwijderen of vergeten',

    // src/templates/other-wifi.html,
    'Choose the Wi-Fi network you are connected to (long press)': 'Kies het wifi-netwerk waarmee je verbonden bent (lang ingedrukt houden)',

    // src/templates/other-wifi.html,
    'Choose the settings of the Wi-Fi network <span class="icon-cog"></span>': 'Kies de instellingen van het wifi-netwerk <span class="icon-cog"></span>',

    // src/templates/install-final-step.html,
    'Click for explanation': 'Klik voor toelichting',

    // src/templates/my-account.html,
    'Click on the button below to terminate your publicroam account.': 'Klik op de knop hieronder om je publicroam-account te beëindigen.',

    // src/templates/install-final-step.html,
    'Click on the drop-down arrow on the right': 'Klik rechts op het uitklap-pijltje',

    // src/templates/account-active.html,
    'Complete registration': 'Aanmelding afronden',

    // src/templates/install-final-step.html,src/templates/menu.html,src/templates/not-connected.html,src/templates/install-success-no-location.html,src/templates/install-success-no-location.html,src/templates/account-active.html,src/templates/not-connected-no-location.html,src/templates/not-connected-no-location.html,
    'Connect other devices': 'Andere apparaten verbinden',

    // src/templates/connected.html,src/templates/install-success-connected.html,src/templates/install-success-not-connected.html,
    'Connect your laptop or other devices:': 'Je laptop of andere apparaten verbinden:',

    // src/src/core/gui.js,
    'Connected': 'Verbonden',

    // src/templates/not-connected.html,src/templates/install-success-not-connected.html,
    'Connected to another wifi?': 'Verbonden met een ander wifi?',

    // src/templates/at-your-organization.html,
    'Connecting is easy': 'Aansluiten is eenvoudig',

    // src/templates/my-account.html,
    'Contact data': 'Contactgegevens',

    // src/templates/intro.html,src/templates/other-wifi.html,
    'Continue': 'Doorgaan',

    // src/src/views/account.js,
    'Could not find an account for your phone number. You can register a new account through <a href="/#/register/again">Register again</a>.': 'We konden geen account vinden voor jouw telefoonnummer. Je kunt een nieuw account aanvragen via <a href="/#/register/again">Opnieuw aanmelden</a>.',

    // src/templates/register.html,src/templates/login-existing.html,
    'Country': 'Land',

    // src/templates/register.html,
    'Create a new account': 'Maak een nieuw account',

    // src/templates/other-wifi.html,
    'Disconnect from the current WiFi-network. Follow the instructions below, don&apost turn off Wi-Fi. Then choose continue.': 'Verbreek de huidige wifi-verbinding. Volg de uitleg hieronder, let op dat je wifi niet uitzet. Klik daarna op doorgaan.',

    // src/templates/other-wifi.html,
    'Do the following (don&apos;t turn off Wi-Fi):': 'Doe het volgende (zet wifi niet uit):',

    // src/templates/how.html,
    'Do you have an idea or suggestion?': 'Heb je een idee of suggestie?',

    // src/templates/join-us.html,
    'Do you want free and secure WiFi via publicroam in more places?': 'Wil je op meer plekken gratis en veilig wifi via publicroam?',

    // src/templates/install-success-connected.html,
    'Do you want publicroam in more places?': 'Wil je publicroam op meer plekken?',

    // src/templates/my-account.html,
    'Do you want to send analysis info on your device to publicroam?': 'Wil je analyse-informatie over je toestel naar publicroam sturen?',

    // src/templates/connect-other-devices.html,
    'Download the publicroam app. Open the app and choose &apos;I already have an account&apos;.': 'Download de publicroam app. Open de app en kies &apos;Ik heb al een account&apos;.',

    // src/templates/register.html,src/templates/my-account.html,
    'E-mail address': 'E-mailadres',

    // src/templates/remove-wifi-profile.html,
    'Finish registration': 'Rond je registratie af',

    // src/templates/remove-wifi-profile.html,
    'Finish your registration': 'Rond je registratie af',

    // src/templates/connect-manually.html,
    'For mobile devices we recommend the app.': 'Voor mobiele apparaten raden we de app aan.',

    // src/templates/home.html,
    'Free, we respect your privacy.': 'Gratis, met respect voor je privacy.',

    // src/templates/my-account.html,
    'Go to': 'Ga naar',

    // src/templates/other-wifi.html,
    'Go to Settings': 'Ga naar Instellingen',

    // src/templates/install-final-step.html,
    'Go to Settings <span class="icon-cog"></span>': 'Ga naar Instellingen <span class="icon-cog"></span>',

    // src/templates/not-connected.html,src/templates/account-active.html,
    'Go to a publicroam location': 'Ga naar een publicroam locatie',

    // src/templates/connect-manually.html,src/templates/connect-other-devices.html,
    'Go to our <a href="https://publicroam.nl/app/en/support" rel="external">support website</a>': 'Ga naar <a href="https://publicroam.nl/app/nl/support" rel="external">support</a>',

    // src/templates/install-final-step.html,
    'Go to settings and wifi and turn wifi OFF and ON.': 'Ga via instellingen naar Wi-Fi, zet dit UIT en AAN.',

    // src/templates/install-final-step.html,
    'Go to the notification <b>Connect to WiFi networks?</b>': 'Ga naar de melding <b>Verbinding maken met Wi-Fi-netwerken?</b>',

    // src/templates/install-failed.html,
    'Have you used publicroam previously?': 'Heb je publicroam eerder gebruikt?',

    // src/templates/install-final-step.html,
    'Haven&apos;t received a notification or are you not connected automatically at a publicroam location? Then do this:': 'Geen melding ontvangen? Of verbind je niet automatisch terwijl je op een publicroam locatie bent? Doe dan dit:',

    // src/templates/join-us.html,
    'Help us build a secure cloud of wifi-networks. We regularly come up with campaigns to draw attention to safe WiFi. So more organizations opt to offer you a good WiFi connection.': 'Bouw mee aan een wolk van veilig wifi-netwerken. Regelmatig komen we met acties om aandacht te vragen voor veilig gastwifi. Zodat meer organisaties je een goede wifi-verbinding bieden.',

    // src/templates/connect-other-devices.html,
    'How can I connect other devices?': 'Hoe kan ik andere apparaten verbinden?',

    // src/templates/my-account.html,
    'How can I connect other<br/>devices?': 'Hoe kan ik andere apparaten<br/>verbinden?',

    // src/templates/menu.html,
    'How does publicroam work': 'Hoe werkt publicroam',

    // src/templates/how.html,
    'How does publicroam work?': 'Hoe werkt publicroam?',

    // src/templates/register.html,
    'I agree to the <a rel="external" class="red" href="https://publicroam.nl/app/en/terms-and-conditions">terms of use</a> and the processing of my personal data for WiFi access (anonymous and ad-free, see <a rel="external" class="red" href="https://publicroam.nl/app/en/privacy-statement">privacy statement</a>).': 'Ik ga akkoord met de <a rel="external" class="red" href="https://publicroam.nl/app/nl/terms-and-conditions">gebruiksvoorwaarden</a> en met verwerking van mijn gegevens voor toegang tot wifi (anoniem en reclamevrij, zie <a rel="external" class="red" href="https://publicroam.nl/app/nl/privacy-statement">privacy statement</a>).',

    // src/templates/register.html,src/templates/login-existing.html,
    'I already have an account': 'Ik heb al een account',

    // src/templates/support.html,
    'If it doesn&apos;t work with the publicroam app, choose <a href="/#/connect-manually">sign in without app</a>': 'Als het niet lukt met de publicroam app, kies dan voor <a href="/#/connect-manually">Aanmelden zonder app</a>',

    // src/templates/connect-manually.html,
    'If not there, go to <a href="https://publicroam.nl/" rel="external">publicroam.nl</a> to register.': 'Staan ze daar niet, ga dan naar <a href="https://publicroam.nl/" rel="external">publicroam.nl</a> om je aan te melden.',

    // src/templates/remove-wifi-profile.html,
    'If there is an old wifi profile active, you need to remove this manually. Afterwards you can finish your registration. Explanation:': 'Als er een oud wifi-profiel actief is, moet je dit eerst handmatig verwijderen. Daarna kun je je registratie afronden. Uitleg:',

    // src/templates/install-final-step.html,
    'If you choose "no" there is no way to change this. Uninstall the app and then download it again.': 'Als je "nee" kiest kun je dit niet meer wijzigen. Deïnstalleer de app en download hem dan opnieuw.',

    // src/templates/api-error.html,
    'If you use an (insecure) open WiFi network, make sure that auto-connect is turned off or remove the WiFi network from your device after use.': 'Als je een (onveilig) open wifi-netwerk gebruikt, zorg dan dat automatisch verbinden uitstaat of verwijder het wifi na gebruik van je toestel.',

    // src/templates/installing.html,
    'Installing publicroam': 'Publicroam wordt geïnstalleerd',

    // src/templates/other-wifi.html,
    'Instructions': 'Uitleg',

    // src/templates/install-failed.html,
    'It is possible that an old wifi profile is still active.': 'Dan kan er een oud wifi-profiel actief zijn.',

    // src/templates/at-your-organization.html,
    'Join publicroam': 'Ook publicroam aanbieden?',

    // src/templates/join-us.html,
    'Join us': 'Bouw mee',

    // src/templates/install-success-connected.html,
    'Join us in building a secure wifi cloud': 'Bouw mee aan een veilige wifi wolk',

    // src/templates/join-us.html,
    'Keep posted via e-mail:': 'Blijf op de hoogte via e-mail:',

    // src/templates/connect-other-devices.html,
    'Laptop or other devices': 'Laptop of andere apparaten',

    // src/templates/my-account.html,
    'Login data': 'Inloggegevens',

    // src/templates/support.html,
    'Make sure you are near a WiFi network with publicroam. Then choose <a class="menu" href="#">Sign in again</a>': 'Zorg dat je in de buurt bent van publicroam. Kies dan voor <a class="menu" href="#">Opnieuw aanmelden</a>',

    // src/templates/support.html,
    'Maybe the WiFi network is not working properly. Check this at your current location.': 'Mogelijk werkt het wifi-netwerk niet goed. Vraag dit na op je huidige locatie',

    // src/templates/register.html,src/templates/login-existing.html,
    'Mobile phone number': 'Mobiele telefoonnummer',

    // src/templates/support.html,
    'More support': 'Meer ondersteuning',

    // src/templates/my-account.html,
    'My Account': 'Mijn account',

    // src/templates/menu.html,src/templates/menu.html,
    'My account': 'Mijn account',

    // src/templates/connect-manually.html,src/templates/connect-other-devices.html,
    'Need more support?': 'Meer ondersteuning?',

    // src/templates/my-account.html,
    'New e-mail address': 'Nieuw e-mailadres',

    // src/templates/my-account.html,
    'No': 'Nee',

    // src/src/views/login.js,
    'No account found. Check the number. Or go <a href="/#/register">back</a>': 'Geen account gevonden. Controleer het nummer. Of ga <a href="/#/register">terug</a>',

    // src/templates/api-error.html,
    'No connection': 'Geen verbinding',

    // src/templates/support.html,
    'Not automatically connected?': 'Verbind je niet automatisch?',

    // src/src/core/gui.js,src/templates/not-connected.html,src/templates/not-connected-no-profile.html,
    'Not connected to publicroam': 'Niet verbonden met publicroam',

    // src/templates/join-us.html,
    'Offer publicroam at your organization': 'Je eigen organisatie aansluiten op publicroam',

    // src/templates/remove-wifi-profile.html,
    'On your device go to the settings screen (gear) and select wifi': 'Ga op je apparaat naar Instellingen (tandwieltje) en kies wifi.',

    // src/templates/not-connected.html,
    'Once there, you will connect automatically.': 'Je verbind daar automatisch.',

    // src/templates/api-error.html,
    'Once your account is active, you will automatically connect to WiFi via publicroam.': 'Zodra je account actief is, maak je voortaan automatisch verbinding met wifi via publicroam.',

    // src/templates/how.html,
    'Online everywhere': 'Overal online',

    // src/templates/install-success-no-location.html,src/templates/not-connected-no-location.html,
    'Or check the WiFi connection yourself, via WiFi settings.': 'Of controleer de wifi-verbinding zelf, via Wifi-instellingen.',

    // src/templates/api-error.html,
    'Or temporarily connect to another WiFi. This can also be done at home or elsewhere.': 'Of verbind tijdelijk met een ander wifi. Dit kan ook thuis of elders.',

    // src/templates/connected.html,src/templates/install-success-not-connected.html,
    'Other devices': 'Andere apparaten',

    // src/templates/404.html,
    'Page could not be found': 'De pagina kon niet worden gevonden',

    // src/templates/my-account.html,
    'Password': 'Wachtwoord',

    // src/templates/my-account.html,
    'Phone number': 'Telefoonnummer',

    // src/src/views/register.js,src/src/views/register.js,src/src/views/login.js,
    'Please check your phone number': 'Controleer je telefoonnummer',

    // src/templates/pincode.html,
    'Please fill in the verification code that has been sent as a text message to ': 'Vul de code in die zojuist per SMS is verzonden naar ',

    // src/templates/at-your-organization.html,
    'Please visit: <a rel="external" class="red" href="https://publicroam.nl/app/en/connect-your-network">connect your network</a>': 'Ga naar: <a rel="external" class="red" href="https://publicroam.nl/app/nl/connect-your-network">sluit je netwerk aan</a>',

    // src/templates/menu.html,
    'Publicroam also at your organization?': 'Publicroam ook bij jouw organisatie?',

    // src/templates/privacy.html,
    'Publicroam collects as little personal data as possible and does not trade in data. What we collect is only used to ensure that our service works properly.': 'Publicroam registreert zo min mogelijk persoonsgegevens en handelt niet in data. Wat we vastleggen gebruiken we alleen om te zorgen dat de dienst goed werkt.',

    // src/templates/how.html,
    'Publicroam gives you secure and automatic access to guest WiFi in many locations.': 'Publicroam geeft je veilig en automatisch toegang tot gastwifi op vele locaties.',

    // src/templates/privacy.html,
    'Publicroam respects your privacy': 'Publicroam respecteert je privacy',

    // src/templates/install-success-no-location.html,src/templates/install-success-no-location.html,src/templates/not-connected-no-location.html,src/templates/not-connected-no-location.html,
    'Ready. You automatically connect to publicroam': 'Gereed. Je verbindt automatisch met publicroam',

    // src/templates/install-success-not-connected.html,
    'Ready. You connect once you are at a publicroam location': 'Gereed. Je verbindt zodra je op een publicroam locatie bent',

    // src/templates/register.html,
    'Register': 'Aanmelden',

    // src/templates/terminate-account-success.html,src/templates/my-account.html,
    'Register again': 'Opnieuw aanmelden',

    // src/templates/remove-wifi-profile.html,
    'Remove the old wifi profile': 'Verwijder het oude wifi-profiel',

    // src/templates/install-failed.html,
    'Remove this and finish your registration': 'Verwijder dit en rond je aanmelding af',

    // src/templates/remove-wifi-profile.html,
    'Remove wifi profile': 'Wifi-profiel verwijderen',

    // src/templates/remove-wifi-profile.html,
    'Reopen the publicroam app': 'Open de publicroam-app opnieuw',

    // src/templates/register.html,src/templates/register.html,
    'Repeat your e-mail address': 'Herhaal je e-mailadres',

    // src/templates/my-account.html,src/templates/my-account.html,
    'Repeat your new e-mail address': 'Herhaal je nieuwe e-mailadres',

    // src/templates/support.html,
    'Report an incident': 'Meld een storing',

    // src/templates/my-account.html,
    'Save': 'Opslaan',

    // src/templates/remove-wifi-profile.html,
    'Select the wifi network ‘publicroam’': 'Kies het wifi-netwerk ‘publicroam’',

    // src/templates/how.html,
    'Send': 'Stuur',

    // src/templates/my-account.html,
    'Send device info': 'Verstuur toestelinfo',

    // src/templates/menu.html,
    'Send feedback': 'Stuur feedback',

    // src/templates/pincode.html,
    'Send me a new text message': 'Stuur mij een nieuwe SMS',

    // src/templates/connected.html,src/templates/install-success-connected.html,src/templates/install-success-not-connected.html,
    'Show login details': 'Ga naar inloggegevens',

    // src/templates/menu.html,
    'Sign in again': 'Opnieuw aanmelden',

    // src/templates/connect-manually.html,src/templates/support.html,
    'Sign in without app': 'Aanmelden zonder app',

    // src/templates/install-failed.html,
    'Sign in without the app.': 'Meld je aan zonder de app.',

    // src/templates/install-failed.html,
    'Signing in via the app dit not work?': 'Aanmelden via de app niet gelukt?',

    // src/src/views/account.js,
    'Something went wrong trying to terminate your account.': 'Er is iets misgegaan met het beëindigen van jouw account.',

    // src/templates/support.html,
    'Still unable to connect?': 'Verbindt je apparaat nog steeds niet?',

    // src/templates/pincode.html,src/templates/login-existing.html,
    'Submit': 'Verzend',

    // src/templates/install-success-connected.html,
    'Success!': 'Gelukt!',

    // src/templates/install-final-step.html,src/templates/support.html,src/templates/menu.html,src/templates/not-connected.html,src/templates/connected.html,src/templates/install-success-no-location.html,src/templates/install-success-no-location.html,src/templates/account-active.html,src/templates/install-success-not-connected.html,src/templates/not-connected-no-location.html,src/templates/not-connected-no-location.html,
    'Support': 'Ondersteuning',

    // src/templates/install-final-step.html,
    'Swipe down on your screen': 'Veeg omlaag over je scherm',

    // src/templates/install-final-step.html,
    'Swipe down to open': 'Veeg omlaag om te openen',

    // src/templates/my-account.html,src/templates/my-account.html,
    'Terminate account': 'Account beëindigen',

    // src/templates/terminating.html,
    'Terminating your account': 'Jouw account beëindigen',

    // src/templates/menu.html,
    'Terms &amp; conditions': 'Gebruiksvoorwaarden',

    // src/templates/pincode.html,
    'Text message has been sent. You can request a new text message in ': 'SMS is verstuurd. Je kunt een nieuwe SMS aanvragen over ',

    // src/templates/install-failed.html,
    'The WiFI profile could not be installed.': 'Het wifi-profiel kon niet worden geïnstalleerd.',

    // src/src/views/register.js,src/src/views/login.js,
    'The account could not be found': 'Het account kon niet worden gevonden.',

    // src/templates/support.html,
    'The app automatically connects you to WiFi as soon as you are at a publicroam location.': 'De app verbindt je automatisch met wifi zodra je op een publicroam-locatie bent.',

    // src/templates/install-success-no-location.html,src/templates/install-success-no-location.html,src/templates/not-connected-no-location.html,src/templates/not-connected-no-location.html,
    'The app cannot check your WiFi status.': 'De app kan je wifi status niet controleren.',

    // src/templates/intro.html,
    'The app requests your location. This is used to check your connection, not for other purposes.': 'De app vraagt naar je locatie. Dit wordt gebruikt om je verbinding te checken, niet voor andere doelen.',

    // src/src/views/register.js,
    'The e-mail address is invalid': 'Het e-mailadres klopt niet',

    // src/src/views/register.js,src/src/views/account.js,
    'The e-mail addresses are not the same': 'De e-mailadressen zijn niet hetzelfde',

    // src/templates/change-phonenumber.html,
    'The phone number that is connected to your publicroam account can not be modified in the app. You can however terminate your current account and afterwards request a new account with another phone number.  In order to terminate your account you can go to <a href="/#/my-account/terminate">Terminate account</a>': 'Het telefoonnummer dat verbonden is aan je publicroam-account kun je niet wijzigen via de app. Wel kun je je huidige account beëindigen en daarna een nieuw account aanvragen met een ander telefoonnummer. Om je account te beëindigen ga je naar <a href="/#/my-account/terminate">Account beëindigen</a>.',

    // src/templates/not-connected-no-profile.html,
    'The publicroam WiFI profile has not yet been installed. <a href="/#/">Try again</a>, or <a href="/#/connect-manually">install manually</a>.': 'Het publicroam wifi-profiel is nog niet geïnstalleerd. Probeer <a href="/#/">nogmaals</a>, of <a href="/#/connect-manually">installeer handmatig</a>.',

    // src/templates/account-inactive.html,
    'The publicroam account on this device is not active anymore. To reconnect to publicroam you can <a href="/#/register">register again</a>.': 'De publicroam-account op dit apparaat is niet meer actief. Om opnieuw met publicroam te verbinden kun je je <a href="/#/register">opnieuw aanmelden</a>.',

    // src/templates/certificate-invalid.html,
    'The publicroam app needs to be updated. The enclosed WiFi-certificate is not valid anymore.': 'De publicroam app heeft een update nodig. Het bijgesloten wifi-certificaat is niet meer geldig.',

    // src/src/views/register.js,src/src/views/register.js,src/src/views/login.js,src/src/views/login.js,
    'The verification code is incorrect': 'De verificatiecode klopt niet',

    // src/templates/api-error.html,
    'Then choose <a href="#" class="menu">Sign in again</a>': 'Kies dan voor <a href="#" class="menu">Opnieuw aanmelden</a>',

    // src/templates/not-connected.html,src/templates/install-success-not-connected.html,
    'Then first disconnect from the other Wi-Fi network. Otherwise you will not be able to connect to publicroam': 'Verbreek dan de verbinding met het andere wifi-netwerk. Anders kun je niet verbinden met publicroam',

    // src/src/views/register.js,
    'There already exists a publicroam account for this phone number. <a href="/#/login-existing">I already have an account</a>.': 'Er bestaat al een publicroam-account voor dit telefoonnummer. <a href="/#/login-existing">Ik heb al een account</a>.',

    // src/src/views/account.js,
    'There was a problem changing your e-mail address. Please try again later.': 'Er was een probleem met het aanpassen van je e-mailadres. Probeer het later nog eens.',

    // src/templates/my-account.html,
    'This username is not active anymore.': 'Deze gebruikersnaam is niet meer actief.',

    // src/templates/api-error.html,
    'To activate your account, your device must be connected to the internet.': 'De app heeft een verbinding met internet nodig om je account te activeren.',

    // src/templates/install-success-no-location.html,src/templates/not-connected-no-location.html,
    'To do this, it needs permission to use your location. Go to Settings &gt; Apps or Manage Apps &gt; App Permissions or Privacy &gt; Location)': 'Hiervoor heeft de app toestemming nodig om je locatie te gebruiken. Ga naar Instellingen &gt; Apps of App-beheer &gt; App-machtigingen of Privacy &gt; Locatie',

    // src/templates/install-success-no-location.html,src/templates/not-connected-no-location.html,
    'To do this, it needs permission to use your location. Go to Settings &gt; Privacy &gt; Location Services.': 'Hiervoor heeft de app toestemming nodig om je locatie te gebruiken. Ga naar Instellingen &gt; Privacy &gt; Locatievoorzieningen.',

    // src/templates/account-active.html,
    'To register this device:': 'Om je toestel aan te melden:',

    // src/templates/install-final-step.html,
    'Touch <b>the arrow on the right</b>': 'Klik op het <b>pijltje rechts</b>',

    // src/templates/connect.html,
    'Trying to connect': 'Proberen te verbinden',

    // src/templates/install-final-step.html,
    'Turn WiFi <b>OFF and ON</b>': 'Zet wifi UIT en AAN',

    // src/templates/api-error.html,
    'Turn on your mobile data connection': 'Zet je mobiele data-verbinding aan',

    // src/templates/at-your-organization.html,
    'Use your existing network': 'Via je bestaande netwerk',

    // src/templates/my-account.html,
    'Username': 'Gebruikersnaam',

    // src/templates/pincode.html,
    'Verification code': 'Verificatiecode',

    // src/templates/install-final-step.html,
    'Wait 2 to 3 minutes for your device to connect': 'Na 2 à 3 minuten verbindt je apparaat',

    // src/templates/install-final-step.html,
    'Wait 4 to 5 minutes for a notification at the top of your screen': 'Wacht 4 à 5 minuten op een melding bovenin je scherm',

    // src/templates/install-final-step.html,
    'Wait for a few minutes': 'Wacht daarna enkele minuten',

    // src/templates/how.html,
    'We are growing': 'We groeien',

    // src/templates/join-us.html,
    'We can use your help!': 'Daar kunnen we je hulp bij gebruiken!',

    // src/src/views/register.js,src/src/views/login.js,
    'We could not retrieve your account information. Please try again later': 'We konden je accountgegevens niet ophalen. Probeer het later nog eens.',

    // src/src/views/register.js,
    'We could not send the verification code, please check your data.': 'We konden de verificatiecode niet versturen, controleer je gegevens.',

    // src/templates/at-your-organization.html,
    'We keep growing': 'Op steeds meer plekken',

    // src/templates/api-error.html,src/templates/install-failed.html,
    'What can you do?': 'Wat kun je doen?',

    // src/templates/privacy.html,
    'What we do with your data': 'Wat we doen met je gegevens',

    // src/templates/account-active.html,
    'When there click: <b>Complete registration</b>': 'Klik daar op: <b>Aanmelding afronden</b>',

    // src/src/core/gui.js,
    'Wifi status unknown': 'Wifi status onbekend',

    // src/templates/at-your-organization.html,
    'With publicroam you offer your visitors safe and easy access to guest WiFi. At your own organization and in many other places. With respect for privacy.': 'Met publicroam bied je je bezoekers veilig en makkelijk toegang tot gastwifi. Bij je eigen organisatie en op vele andere plekken. Met respect voor privacy.',

    // src/templates/my-account.html,
    'Yes': 'Ja',

    // src/templates/install-success-no-location.html,src/templates/not-connected-no-location.html,
    'You can also check the WiFi connection yourself, via WiFi settings.': 'Je kunt de wifi-verbinding ook zelf controleren, via Wifi-instellingen.',

    // src/templates/connect-manually.html,
    'You can also connect your devices manually, without the app': 'Je kunt je apparaten ook handmatig aanmelden, zonder app',

    // src/templates/at-your-organization.html,
    'You can find publicroam in more and more places. At municipalities, libraries, museums, theatres, sports locations, shared workspaces, healthcare institutions, etc.': 'Je vindt publicroam op steeds meer plekken. Bij gemeenten, bibliotheken, musea, theaters, sportlocaties, deelwerkplekken, zorginstellingen, etc.',

    // src/templates/how.html,
    'You can find us in more and more places. At municipalities, librairies and musea. And also at theaters, sports locations, workspaces, health care institutions, etc.': 'Je vindt ons op steeds meer plekken. Bij gemeenten, in bibliotheken, in musea. En ook in theaters, sportlocaties, flexwerkplekken, zorginstellingen, etc.',

    // src/templates/connect-other-devices.html,
    'You can find your login details in <a href="/#/my-account/login">My account</a> in this app. Go to WiFi settings on the other device. Select the WiFi network &apos;publicroam&apos;. Enter your login details and connect.': 'Je inloggegevens vind je in deze app onder <a href="/#/my-account/login">Mijn account</a>. Ga naar wifi-instellingen op het andere apparaat. Selecteer het wifi-netwerk &apos;publicroam&apos;. Voer je inloggegevens in en verbind.',

    // src/templates/connect-manually.html,
    'You can find your login details in this app under <a href="/#/my-account/login">My account</a>': 'Je inloggegevens vind je in deze app onder <a href="/#/my-account/login">Mijn account</a>',

    // src/templates/install-success-not-connected.html,
    'You can now close the app.': 'Je kunt de app nu sluiten.',

    // src/templates/connected.html,src/templates/install-success-connected.html,
    'You connect automatically and securely wherever there&apos;s publicroam': 'Je gaat automatisch en veilig online overal waar publicroam is',

    // src/templates/how.html,
    'You create a free account once and then go online immediately, wherever there is publicroam. The security ensures that your WiFi connection is well protected.': 'Je maakt één keer een gratis account aan en gaat dan direct online, overal waar publicroam is. De beveiliging zorgt ervoor dat je wifi verbinding goed beschermd is.',

    // src/templates/install-final-step.html,
    'You have to do this in the settings screen, not with the shortcut.': 'Doe dit via Instellingen, niet via de wifi-snelkoppeling',

    // src/src/views/register.js,
    'You need to agree with the terms and conditions': 'Je moet akkoord gaan met de voorwaarden',

    // src/templates/my-account.html,
    'You will not be able to connect anymore using this account, also not on other devices.': 'Je kunt dan niet meer verbinden met dit account, ook niet op andere apparaten.',

    // src/templates/login-existing.html,
    'You will receive a text message with a confirmation code': 'Je ontvangt een SMS met een verificatiecode',

    // src/templates/account-active.html,
    'Your account is active': 'Je account is actief',

    // src/src/views/account.js,
    'Your device information could not be sent. Please try again later.': 'Je toestelinformatie kon niet worden verzonden. Probeer het later nog eens.',

    // src/src/views/account.js,
    'Your device information has been sent successfully.': 'Je toestelinformatie is succesvol verstuurd.',

    // src/templates/other-wifi.html,
    'Your device is connected to another WiFi': 'Je apparaat is verbonden met een ander wifi',

    // src/templates/connected.html,
    'Your device is connected to publicroam': 'Je apparaat is verbonden met publicroam',

    // src/templates/install-success-not-connected.html,
    'Your device will automatically connect once you are at a publicroam location.': 'Je apparaat zal automatisch verbinden zodra je op een publicroam locatie bent.',

    // src/templates/register.html,
    'Your e-mail address': 'Jouw e-mailadres',

    // src/src/views/account.js,
    'Your e-mail address was modified successfully.': 'Je e-mailadres is succesvol aangepast.',

    // src/templates/privacy.html,
    'Your location is needed to connect you to publicroam and to inform you about the status of your connection. Your mobile phone number is necessary to give you a personal account. Your e-mail address is used for service messages and support. For our newsletter you can subscribe and unsubscribe separately. Read more in our <a rel="external" href="https://publicroam.nl/app/en/privacy-statement">privacy statement</a>.': 'Je locatie is nodig om je te verbinden met publicroam en om je te laten weten wat de status is van je verbinding. Je 06-nummer is nodig om je een persoonlijk account te verstrekken. Je e-mailadres gebruiken we om je serviceberichten te sturen en voor eventuele ondersteuning. Voor de nieuwsbrief kun je je apart aan- en afmelden. Lees meer in ons <a rel="external" href="https://publicroam.nl/app/nl/privacy-statement">privacy statement</a>',

    // src/templates/register.html,src/templates/login-existing.html,
    'Your mobile phone number': 'Jouw mobiele telefoonnummer',

    // src/templates/my-account.html,
    'Your new e-mail address': 'Je nieuwe e-mailadres',

    // src/templates/terminate-account-success.html,
    'Your publicroam account has been terminated': 'Je publicroam-account is beëindigd',

    // src/src/core/events.js,
    'hide': 'verberg',

    // src/templates/connect-other-devices.html,
    'iPhone/iPad or Android': 'iPhone/iPad of Android',

    // src/templates/my-account.html,src/templates/my-account.html,
    'modify': 'wijzigen',

    // src/src/views/register.js,src/src/views/register.js,
    'second': 'seconde',

    // src/src/views/register.js,src/src/views/register.js,
    'seconds': 'seconden',

    // src/src/core/events.js,src/templates/my-account.html,
    'show password': 'bekijk wachtwoord',

    // ====================================================
    // TODO: translate these
    // ====================================================
    // src/templates/home.html,
    'Start': 'Start',

};

export function getTranslations() {
    return translations;
}
