/**
 * Main module.
 * @module main
 */

"use strict";

import * as Constants from './core/constants.js';
import * as Events from './core/events.js';
import * as Gui from './core/gui.js';
import * as Helpers from './core/helpers.js';
import * as I8n from './core/i8n.js';
import * as Routing from './routing.js';
import * as Session from './core/session.js';
import * as Middleware from './core/middleware.js';
import * as Watcher from './core/watcher.js';

window.setTimeout(() => {
    const el = document.getElementById('splash');
    el.parentNode.removeChild(el);
}, 2000);

function ready() {
    if(typeof cordova !== 'undefined') {
        window.open = cordova.InAppBrowser.open;
    } else {
        window.debug = true;
    }

    if(window.debug){
        $('a.debug').show();
    }

    Session.initSession(() => {
        I8n.initTranslations();
        Gui.initGui();
        Events.initEvents();
        Routing.initRouting();
        Watcher.initWatcher(() => {
            const path = window.location.pathname + window.location.hash;
            Routing.visit(path);
        });
    });
}

$(document).ready(() => {
    if(window.hasOwnProperty("cordova")){
        document.addEventListener("deviceready", ready, false);
    } else {
        ready();
    }
});
