/**
 * Debug view module.
 * @module static
 */

"use strict";

import * as Gui from '../core/gui.js';
import * as Session from '../core/session.js';
import * as Constants from '../core/constants.js';
import * as Middleware from '../core/middleware.js';

export class DebugView {
    constructor() {
        this.viewName = 'DebugView';
    }

    install(event, args) {
        if(window.hasOwnProperty("cordova")){
            var success = function(message) {
                alert("Install success");
            };

            var failure = function() {
                alert("Install failed");
            };

            alert('Setting up wifi profile');
            jwpm.setupWifiProfile(Constants.CERTIFICATE, Session.getVar('username'), Session.getVar('password'), success, failure);
       }
    }

    scan(event, args) {
        Middleware.publicroamNearby().then(() => {
            alert('Publicroam found');
        }).catch(() => {
            alert('Publicroam not found');
        });
    }

    connect(event, args) {
        if(window.hasOwnProperty("cordova")){
            var success = function(message) {
                alert("Connected");
            };

            var failure = function(message) {
                alert("Forced connecting failed: " + message);
            };

            alert('Connecting (forced connecting will always fail on iOS)');
            jwpm.connect(success, failure);
       }
    }


    terminate(event, args) {
        if(window.hasOwnProperty("cordova")){
            var success = function(message) {
                alert("Removed profile");
            };

            var failure = function() {
                alert("Could not delete profile");
            };

            alert('Removing wifi profile');
            jwpm.terminate(success, failure);
       }
    }


    isConnected(event, args) {
        if(window.hasOwnProperty("cordova")){
            var success = function(message) {
                alert('We are connected');
            };

            var failure = function() {
                alert("Not connected");
            };

            alert('Check connection status');
            jwpm.isConnected(success, failure);
       }
    }

    permissions(event, args) {
        if(window.hasOwnProperty("cordova")){
            var success1 = function(message) {
                alert('We have location permissions');
            };

            var failure1 = function() {
                alert('We do not have location permissions');
            };

            alert('Get location permissions');
            jwpm.hasPermissions(success1, failure1);
       }
    }

    currentSsid(event, args) {
        alert('Trying to retrieve current SSID');
        Middleware.getCurrentSsid().then((ssid) => {
            alert('SSID name is: ' + ssid);
        }).catch(() => {
            alert('Could not retrieve SSID');
        });
    }

    render(event, args, data=false) {
        if(data !== false) {
            Session.setConnected(data.hasOwnProperty('connected') && data.connected === 'true');
            Session.setVar('hasProfile', data.hasOwnProperty('has-wifi-profile') && data['has-wifi-profile'] === '1');
            Session.setVar('hasAccount', data.hasOwnProperty('has-account') && data['has-account'] === '1'); 
            Session.setVar('locationAccess', data.hasOwnProperty('location-access') && data['location-access'] === '1');
            Session.setVar('certificateValid', data.hasOwnProperty('certificate-valid') && data['certificate-valid'] === '1');
            Session.setVar('publicroamNearby', data.hasOwnProperty('publicroam-nearby') && data['certificate-valid'] === '1');
            Session.setVar('apiAvailable', data.hasOwnProperty('api-available') && data['api-available'] === '1');
            Session.setVar('useRealApi', data.hasOwnProperty('use-real-api') && data['use-real-api'] === '1');
            Session.setVar('phoneNumberHasAccount', data.hasOwnProperty('phone-number-has-account') && data['phone-number-has-account'] === '1');
            window.localStorage.setItem('sessionVariables', JSON.stringify(Session.sessionVariables));
            window.localStorage.setItem('connected', Session.isConnected());
        }

        Gui.render('debug.html', {
            'backButton': true,
            'session': Session,
            'connected': Session.isConnected(),
            'sesVars': Session.sessionVariables
        });
    }

}
