/**
 * Events module.
 * The Events module is the main event system for handling link clicks and
 * form submits.
 * @module events
 */

"use strict";

import {log, createNewEvent} from './helpers.js';
import * as Routing from '../routing.js';
import * as Gui from '../core/gui.js';
import * as Session from '../core/session.js';

export function initEvents() {
    log('Initializing events');

    $(document).on('click', 'a.menu', toggleMenu);
    $(document).on('click', 'a.back, button.back', backButtonClick);
    $(document).on('click', 'a.toggle-show', toggleShow);
    $(document).on('click', "a:not([rel='external']):not(.toggle-show):not([rel=mailto]):not([rel=tel]):not(.back)", linkClick);
    $(document).on('submit', 'form', formSubmit);
    $(document).on('click', 'a[rel=external]', openInAppBrowser);
    $(document).on('change', 'select.language', changeLanguage);
    $(document).on('input', 'input[name=pincode]', pincodeFocus);
    $(document).on('click', 'div.phone-number', () => {
        $('input[name=phone]').focus();
    });
    $(document).on('click', 'section.network-status', () => {
        Routing.visit('/#/');
    });

    $(document).on('change', 'select[name=country]', (ev) => {
        $('span.country-prefix').html($(ev.currentTarget).val());
    });

    $(document).on('click', 'a.change-email', (ev) => {
        $('form.change-email').toggle();
    });

    $(window).on('popstate', function(event){
        Routing.visit(
            window.location.pathname + window.location.hash,
            createNewEvent('popstate'),
            false,
            false
        );
    });
}

/**
 * General event handler for link clicks. This is called on all links ("a
 * tags" that don't have rel="external". It automatically prevents the
 * default handler from taking action, and stops propagation (so events
 * don't bubble op the DOM). After this, it calls the [[Routing.visit]]
 * function, so it can take appropriate action on this event.
 * @param {Event} event - the Event object
 */
function linkClick(event) {
    var href = $(event.currentTarget).attr('href');

    event.preventDefault();
    event.stopPropagation();

    // Inactive link? Don't do anything
    if($(event.currentTarget).hasClass('disabled') || href === '#') {
        return;
    }

    // Clicked on a menu item, hide the menu
    if(
        $(event.currentTarget).parents('.main-menu').length > 0 ||
        $(event.currentTarget).parents('.extra-menu').length > 0 ||
        $(event.currentTarget).hasClass('debug') ||
        $(event.currentTarget).hasClass('home') ||
        $(event.currentTarget).hasClass('close-menu')
    ){
        $('header a.menu span').removeClass('icon-cancel');
        $('header a.menu span').addClass('icon-menu');
        $('section.menu').hide();
        $('div.container').show();
        $('header a.menu').attr('data-visible', '0');
    }

    if($('form.register-form').length > 0){
        let formData = $('form.register-form').serializeArray().reduce(function(obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});
        formData.agreed = $('input[name=agree-terms]').is(':checked');
        window.formData = formData;
    }

    Routing.visit(
        href,
        event,
        false,
        $(event.currentTarget).attr('data-no-history') !== '1'
    );
}

/**
 * Clicked on a link with the class toggle-show. This will show or hide the DOM
 * element that matches the id given by the data-target attribute.
 * @param {Event} event - the Event object
 */
function toggleShow(event) {
    event.preventDefault();
    event.stopPropagation();
    var target = $(event.currentTarget).attr('data-target');
    if(target) {
        let el = $('#' + target);
        if(el.is(':visible')){
            el.hide();
            $(event.currentTarget).html(_('show password'));
        } else {
            el.fadeIn();
            $(event.currentTarget).html(_('hide'));
        }
    }
}

function pincodeFocus(event){
    var target = $(event.currentTarget);
    if(target.val().length === 6){
        target.blur();
    }
}

/**
 * General event handler for form submits. This is called on all forms that
 * trigger the submit event. It prevents the default handler from taking
 * action, but does not stop propagation. It passes the event through to
 * [[Routing.visit]] so it can take appropriate action on this event.
 * @param {Event} event - the Event object
 */
function formSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    var data = $(event.currentTarget).serializeArray().reduce(function(obj, item) {
        obj[item.name] = item.value;
        return obj;
    }, {});

    Routing.visit($(event.currentTarget).attr('action'), event, data);
}

/**
 * Open the inappbrowser for given link
 * @param {Event} event - the Event object
 */
function openInAppBrowser(event) {
    event.preventDefault();
    event.stopPropagation();
    var href = $(event.currentTarget).attr('href');
    window.open(href, '_blank', 'location=no,footer=yes,fullscreen=no');
}

function toggleMenu(event=false) {
    event.preventDefault();
    event.stopPropagation();
    let isVisible = $('header a.menu').attr('data-visible') === '1';

    if(!isVisible){
        Gui.setupMenu();
        $('header a.menu span').removeClass('icon-menu');
        $('header a.menu span').addClass('icon-cancel');
        $('section.menu').fadeIn();
        $('header a.menu').attr('data-visible', '1');
        $('div.container').hide();
    } else {
        hideMenu();
    }
}

function hideMenu() {
    $('header a.menu span').removeClass('icon-cancel');
    $('header a.menu span').addClass('icon-menu');
    $('section.menu').hide();
    $('div.container').show();
    $('header a.menu').attr('data-visible', '0');
}

function changeLanguage(event) {
    event.preventDefault();
    event.stopPropagation();
    Session.setLanguage($(event.currentTarget).val());
    Gui.setupMenu();
    Routing.refresh();
    Gui.refreshConnectionStatus(true);
}

function backButtonClick(event) {
    event.stopPropagation();
    event.preventDefault();
    if($('header a.menu').attr('data-visible') === '0'){
        history.back();
    }
    hideMenu();
}
