/**
 * Watcher module.
 * @module watcher
 */

"use strict";

import * as Gui from './gui.js';
import * as Session from './session.js';
import * as Middleware from './middleware.js';

let isWatching = true;

export function initWatcher(callback){
    watch();
    window.setInterval(watch, 5000);
    callback();
}

export function stopWatching() {
    isWatching = false;
}

export function continueWatching() {
    isWatching = true;
}

function watch(){
    if(!isWatching) {
        return;
    }

    Gui.refreshConnectionStatus();

    Middleware.hasLocationPermissions().then(() => {
        Session.setVar('locationAccess', 1);

        Middleware.isConnected().then(() => {
            Session.setConnected(true);
            Gui.refreshConnectionStatus();

            switch(window.location.hash){
                case '#/not-connected':
                case '#/not-connected-no-location':
                    window.visit('/#/connected');
                    break;
                case '#/install-failed':
                case '#/install-final-step':
                case '#/install-success-not-connected':
                    window.visit('/#/install-success-connected');
                    break;
                default:
                    break;
            }
        }).catch(() => {
            Session.setConnected(false);
            Gui.refreshConnectionStatus();

            switch(window.location.hash){
                case '#/connected':
                case '#/install-success-connected':
                case '#/not-connected-no-location':
                    window.visit('/#/not-connected');
                    break;
                default:
                    break;
            }
        });

    }).catch(() => {
        Session.setVar('locationAccess', 0);
        Session.setConnected(false);
        Gui.refreshConnectionStatus();

        switch(window.location.hash){
            case '#/connected':
            case '#/not-connected':
                window.visit('/#/not-connected-no-location');
                break;
            case '#/install-success-connected':
            case '#/install-success-not-connected':
                window.visit('/#/install-success-no-location');
                break;
            default:
                break;
        }
    });

}
