/**
 * Middleware module. This module handles all the calls to the OS for setting
 * up WiFi profiles, checking the connection status..etc. This module is also
 * responsible for all the API calls to the publicroam webserver.
 */

"use strict";

import * as Api from './api.js';
import * as Constants from './constants.js';
import {debugConfirm, debugAlert, parseJson} from './helpers.js';
import * as Session from './session.js';

/**
 * Try to connect to publicroam with the current setup
 * @returns {Promise}
 */
export function connect() {
    return new Promise(function (resolve, reject) {
        if(window.debug === true && !window.hasOwnProperty('cordova')){
            window.setTimeout(function(){
                let success = debugConfirm('Is het verbinden geslaagd?');
                if(success){
                    resolve();
                } else {
                    reject();
                }
            }, 3000);
        } else {
            if(window.hasOwnProperty('cordova')) {
                jwpm.connect(resolve, reject);
            } else {
                reject();
            }
        }
    });
}

/**
 * Check if we are connected to publicroam
 * @returns {Promise}
 */
export function isConnected() {
    return new Promise(function (resolve, reject) {
        if(window.debug === true && !window.hasOwnProperty('cordova')) {
            if(window.localStorage.getItem('connected') && window.localStorage.getItem('connected') !== "false") {
                return resolve();
            } else {
                return reject();
            }
        }

        if(window.hasOwnProperty('cordova')) {
            return jwpm.isConnected(resolve, reject);
        } else {
            return reject();
        }
    });
}

/**
 * Remove the publicroam WiFi profile
 * @returns {Promise}
 */
export function terminate() {
    return new Promise(function (resolve, reject) {
        if(window.debug === true && !window.hasOwnProperty('cordova')){
            return true;
        } else {
            if(window.hasOwnProperty('cordova')) {
                jwpm.terminate(resolve, reject);
            } else {
                reject();
            }
        }
    });
}

/**
 * Try to configure and install the publicroam WiFi profile
 * @returns {Promise}
 */
export function setupProfile() {
    return new Promise(function (resolve, reject) {
        if(window.hasOwnProperty('cordova')) {
            jwpm.setupWifiProfile(
                Constants.CERTIFICATE,
                Session.getVar('username'),
                Session.getVar('password'),
                resolve,
                reject
            );
        } else {
            if(window.debug === true) {
                let success = debugConfirm('Is het installeren van het WIFI-profiel geslaagd?');
                if(success){
                    resolve();
                } else {
                    reject();
                }
            } else {
                resolve();
            }
        }
    });
}

export function requestPincode(phoneNumber, email) {
    return new Promise(function (resolve, reject) {
        if(window.debug === true && !Session.getVar('useRealApi')){
            debugAlert('Nu wordt er een API call gedaan met de ingevulde gegevens, waarna er een SMS wordt verstuurd ter verificatie.');
            if(!Session.getVar('apiAvailable')){
                return reject(Constants.ERROR_API_NOT_AVAILABLE);
            }

            let success = debugConfirm('Is de API call geslaagd?');
            if(success) {
                return resolve();
            } else {
                return reject(Constants.ERROR_PINCODE_CALL_FAILED);
            }
        } else {
            Api.createAccount(phoneNumber, email, resolve, (xhr) => {
                let errorMessage = Constants.ERROR_PINCODE_CALL_FAILED;
                let data = parseJson(xhr.responseJSON);

                if(data !== false){
                    if(data.hasOwnProperty('message_key')) {
                        let messageKey = data.message_key;
                        if(messageKey === 'account_already_exists') {
                            errorMessage = Constants.ERROR_ACCOUNT_ALREADY_EXISTS;
                        } else if(messageKey === 'account_creation_failed'){
                            errorMessage = Constants.ERROR_ACCOUNT_CREATION_FAILED;
                        }
                    }
                }
                return reject({type: errorMessage, body: data});
            });
        }
    });
}

export function verifyPincode(phoneNumber, pincode) {
    return new Promise(function (resolve, reject) {
        if(window.debug === true && !Session.getVar('useRealApi')){
            debugAlert('Nu wordt er een API call gedaan ter verificatie van de ingevulde pincode.');
            if(pincode === '123456'){
                return resolve();
            } else {
                return reject();
            }
        } else {
            Api.verifyAccount(phoneNumber, pincode, resolve, (xhr) => {
                let data = parseJson(xhr.responseJSON);
                let err = false;
                if(data !== false && data.hasOwnProperty('message_key') && data.message_key === 'account_not_found') {
                    err = Constants.ERROR_ACCOUNT_NOT_FOUND;
                }
                reject({type: err, body: data});
            });
        }
    });
}


export function addDevice(phoneNumber) {
    return new Promise(function (resolve, reject) {
        Api.addDevice(phoneNumber, resolve, (xhr) => {
            let data = parseJson(xhr.responseJSON);
            let err = false;
            if(data !== false && data.hasOwnProperty('message_key') && data.message_key === 'account_not_found') {
                err = Constants.ERROR_ACCOUNT_NOT_FOUND;
            }
            reject({type: err, body: data});
        });
    });
}


export function deleteAccount(phoneNumber) {
    return new Promise(function (resolve, reject) {
        if(window.debug === true && !Session.getVar('useRealApi')){
            debugAlert('Nu wordt er een API call gedaan om de account te verwijderen.');
            resolve();
        } else {
            Api.deleteAccount(phoneNumber, resolve, (xhr) => {
                let data = parseJson(xhr.responseJSON);
                let err = Constants.ERROR_UNKNOWN;
                if(data !== false && data.hasOwnProperty('message_key') && data.message_key === 'account_not_found') {
                    err = Constants.ERROR_ACCOUNT_NOT_FOUND;
                }
                reject({type: err, body: data});
            });
        }
    });
}

export function changeEmail(email) {
    return new Promise(function (resolve, reject) {
        const token = Session.getVar('token');
        Api.changeEmail(email, token, resolve, (xhr) => {
            let data = parseJson(xhr.responseJSON);
            let err = Constants.ERROR_UNKNOWN;
            reject({type: err, body: data});
        });
    });
}

export function setupLocationPermissions(){
    return new Promise(function (resolve, reject) {
        if(window.debug && !window.hasOwnProperty('cordova')){
            if(Session.getVar('locationAccess')){
                resolve();
            } else {
                reject();
            }
        } else {
            jwpm.setupPermissions(resolve, reject);
        }
    });
}

export function hasLocationPermissions(){
    return new Promise(function (resolve, reject) {
        if(window.debug && !window.hasOwnProperty('cordova')){
            if(Session.getVar('locationAccess')){
                resolve();
            } else {
                reject();
            }
        } else {
            jwpm.hasPermissions(resolve, reject);
        }
    });
}

export function getLocationPermissions(){
    return new Promise(function (resolve, reject) {
        if(window.debug === true && !window.hasOwnProperty('cordova')) {
            alert('Nu wordt de gebruiker gevraagd om locatiepermissie te geven. Geef je toestemming?');
            Session.refreshLocationPermissions(resolve, reject);
        } else {
            jwpm.setupPermissions(resolve, reject);
        }
    });
}

export function accountCheck() {
    return new Promise((resolve, reject) => {
        const countryCode = Session.getVar('countryCode');
        const phoneNumber = Session.getVar('phoneNumber');
        // NB: this check always fails when I add the prefix +
        const fullPhoneNumber = (countryCode + phoneNumber).replace('+','');
        const password = Session.getVar('password');
        const username = Session.getVar('username');
        Api.accountCheck(fullPhoneNumber, username, password, resolve, (xhr) => {
            let data = parseJson(xhr.responseJSON);

            if(data === false || !data.hasOwnProperty('message_key')){
                reject(Constants.ERROR_UNKNOWN);
            }

            if(xhr.status === 400 && data.message_key === 'account_not_found') {
                return reject(Constants.ERROR_ACCOUNT_NOT_FOUND);
            }

            if(xhr.status === 200 && data.message_key === 'credentials_successful'){
                return resolve();
            }

            return reject(Constants.ERROR_UNKNOWN);
        });
    });
}


/**
 * Check whether there is a network called "publicroam" available
 * returns {Promise}
 */
export function publicroamNearby() {
    return new Promise((resolve, reject) => {
        jwpm.scan(resolve, reject);
    });
}

/**
 * Retrieve the current SSID name (Android only)
 * @returns {Promise}
 */
export function getCurrentSsid() {
    return new Promise((resolve, reject) => {
        jwpm.getCurrentSsid(resolve, reject);
    });
}

export function sendDeviceInfo() {
    return new Promise((resolve, reject) => {
        Api.sendDeviceInfo(resolve, reject);
    });
}
