/**
 * Routing module.
 * @module routing
 */

"use strict";

import {log, createNewEvent} from './core/helpers.js';
import {Url} from './core/url.js';
import * as Gui from './core/gui.js';
import {HomeView} from './views/home.js';
import {RegisterView} from './views/register.js';
import {StaticView} from './views/static.js';
import {ConnectView} from './views/connect.js';
import {DebugView} from './views/debug.js';
import {AccountView} from './views/account.js';
import {LoginView} from './views/login.js';

/**
 * List of Urls.
 * @private
 */
let urls = [];

/**
 * The current URL
 * @private
 */
let currentUrl;

/**
 * Initialize the routing, setting up all the urls.
 * @code Example:
 * <pre>
 * urls = [
 *     new Url('/form/', TestView, 'render'),
 *     new Url('/form/result/', TestView, 'formSubmit'),
 * ];
 * </pre>
 */
export function initRouting() {
    log('Initializing Routing');
    urls = [
        new Url('/', HomeView),
        new Url('/#/', HomeView),
        new Url('/#/intro', StaticView, 'intro'),
        new Url('/#/account-active', ConnectView, 'accountActive'),
        new Url('/#/register', RegisterView),
        new Url('/#/register/again', RegisterView, 'registerAgain'),
        new Url('/#/register/submit', RegisterView, 'submit'),
        new Url('/#/register/pincode', RegisterView, 'pincode'),
        new Url('/#/register/pincode/another', RegisterView, 'anotherPincode'),
        new Url('/#/register/pincode/submit', RegisterView, 'pincodeSubmit'),
        new Url('/#/login-existing', LoginView),
        new Url('/#/login-existing/submit', LoginView, 'submit'),
        new Url('/#/login-existing/pincode', LoginView, 'pincode'),
        new Url('/#/login-existing/pincode/submit', LoginView, 'pincodeSubmit'),
        new Url('/#/terms', StaticView, 'renderTerms'),
        new Url('/#/join-us', StaticView, 'joinUs'),
        new Url('/#/privacy', StaticView, 'privacy'),
        new Url('/#/connect-other-devices', StaticView, 'connectOtherDevices'),
        new Url('/#/connect-manually', StaticView, 'connectManually'),
        new Url('/#/remove-wifi-profile', StaticView, 'removeWifiProfile'),
        new Url('/#/connect', ConnectView),
        new Url('/#/index.html', HomeView),
        new Url('/#/installing', ConnectView, 'installing'),
        new Url('/#/other-wifi', ConnectView, 'otherWifi'),
        new Url('/#/install-again', ConnectView, 'installAgain'),
        new Url('/#/install-success-connected', ConnectView, 'installSuccessConnected'),
        new Url('/#/install-success-no-location', ConnectView, 'installSuccessNoLocation'),
        new Url('/#/install-success-not-connected', ConnectView, 'installSuccessNotConnected'),
        new Url('/#/install-final-step', ConnectView, 'installFinalStep'),
        new Url('/#/locations', StaticView, 'locations'),
        new Url('/#/todo', HomeView, 'todo'),
        new Url('/#/connected', ConnectView, 'connected'),
        new Url('/#/not-connected', ConnectView, 'notConnected'),
        new Url('/#/not-connected-no-location', ConnectView, 'notConnectedNoLocation'),
        new Url('/#/not-connected-no-profile', ConnectView, 'notConnectedNoProfile'),
        new Url('/#/debug/install', DebugView, 'install'),
        new Url('/#/debug/scan', DebugView, 'scan'),
        new Url('/#/debug/connect', DebugView, 'connect'),
        new Url('/#/debug/isconnected', DebugView, 'isConnected'),
        new Url('/#/debug/terminate', DebugView, 'terminate'),
        new Url('/#/debug/permissions', DebugView, 'permissions'),
        new Url('/#/debug/currentssid', DebugView, 'currentSsid'),
        new Url('/#/debug', DebugView),
        new Url('/#/install-failed', ConnectView, 'installFailed'),
        new Url('/#/api-error', StaticView, 'apiError'),
        new Url('/#/certificate-invalid', StaticView, 'certificateInvalid'),
        new Url('/#/at-your-organization', StaticView, 'atYourOrganization'),
        new Url('/#/how', StaticView, 'how'),
        new Url('/#/support', StaticView, 'support'),
        new Url('/#/my-account/:expand', AccountView),
        new Url('/#/my-account', AccountView),
        new Url('/#/change-email', AccountView, 'changeEmail'),
        new Url('/#/change-phonenumber', StaticView, 'changePhoneNumber'),
        new Url('/#/terminate-account', AccountView, 'terminateAccount'),
        new Url('/#/terminate-account-success', AccountView, 'terminateAccountSuccess'),
        new Url('/#/send-device-info', AccountView, 'sendDeviceInfo'),
    ];
    window.visit = visit;
}

/**
 * Try to visit the given url. Sequentially tries to match the given url to
 * all the Urls provided in [[Routing.urls]]. If there's a match it will
 * visit that url by calling its [[Url.visit]]
 * @param {string} url - the url, without the domain name
 * @param {Event} event - the event triggering this visit call
 * @param {object} data - optional form data object
 * @param {boolean} addToHistory - whether to push the current state
 */
export function visit(url, event = createNewEvent(''), data=false, addToHistory=true) {
    log(`Try to visit ${url}`);
    let targetUrl;

    try {
        let matchedUrl = _matchUrl(url);
        targetUrl = matchedUrl;
    } catch (e){
        Gui.show404();
        if(url !== '/') {
            return visit('/');
        }
        return log(`Could not match "${url}"`);
    }

    let prevUrl = '/';

    if(currentUrl && currentUrl.hasOwnProperty('url')) {
        prevUrl = window.location.pathname + window.location.hash;
    }

    if(addToHistory) {
        try {
            window.history.pushState({}, '', url);
        } catch(e){
            console.log(e);
        }
    }

    if(currentUrl){
        currentUrl.destructor();
    }

    currentUrl = targetUrl;
    targetUrl.visit(url, event, data);
    window.scrollTo(0, 0);
}

export function refresh(){
    visit('/' + window.location.hash);
}

/**
 * _matchUrl takes an url and tries to match it with the current list of urls,
 * if it matches one it returns the url it matches with, otherwise it throws a
 * new UrlNotFound Exception
 * @param {string} url - the url, without the domain name
 * @private
 */
function _matchUrl(url) {
    for(let i = 0; i < urls.length; i++){
        let targetUrl = urls[i];
        if(targetUrl.match(url)) {
            return targetUrl;
        }
    }
    throw {
        'name': 'UrlNotFound',
        'message': `Could not find url: "${url}"`
    };
}
