/**
 * Static view module.
 * @module static
 */

"use strict";

import * as Gui from '../core/gui.js';
import {openInappBrowser} from '../core/helpers.js';
import * as Session from '../core/session.js';

export class StaticView {
    constructor() {
        this.viewName = 'StaticView';
    }

    renderTerms() {
        return openInappBrowser('https://publicroam.nl/app/' + Session.getLanguage() + '/terms-and-conditions', {
            'backButton': true
        });
    }

    changePhoneNumber() {
        return Gui.render('change-phonenumber.html', {
            'backButton': true
        });
    }

    apiError() {
        return Gui.render('api-error.html', {
            'backButton': true
        });
    }

    atYourOrganization() {
        return Gui.render('at-your-organization.html', {
            'backButton': true
        });
    }

    how() {
        return Gui.render('how.html', {
            'backButton': true
        });
    }

    support() {
        return Gui.render('support.html', {
            'backButton': true
        });
    }

    locations() {
        return Gui.render('locations.html', {
            'backButton': true,
        });
    }

    certificateInvalid() {
        return Gui.render('certificate-invalid.html');
    }

    connectOtherDevices() {
        return Gui.render('connect-other-devices.html', {
            'backButton': true,
        });
    }

    connectManually() {
        return Gui.render('connect-manually.html', {
            'backButton': true,
        });
    }

    privacy() {
        return Gui.render('privacy.html', {
            'backButton': true,
        });
    }

    joinUs() {
        return Gui.render('join-us.html', {
            'backButton': true,
        });
    }

    removeWifiProfile() {
        return Gui.render('remove-wifi-profile.html', {
            'backButton': true,
        });
    }

    intro() {
        return Gui.render('intro.html', {
            'backButton': true,
        });
    }
}
