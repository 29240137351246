/**
 * The I8n module handles all translations. It offers a handy function "_" that
 * can quickly be used to translate a string. This function is available in the
 * Global namespace
 */

"use strict";

import * as Session from './session.js';
import * as Gui from './gui.js';
import {log} from './helpers.js';
import * as Nl from '../locale/nl.js';

/**
 * A mapping for all translations
 * @private
 */
let translations = {};

export function initTranslations() {
    window._ = _;
    addTranslation('nl', Nl.getTranslations());
}

/**
 * Adds a translation to the translations mapping
 * @param language the language
 * @param translation the translation dictionary
 */
export function addTranslation(language, translation){
    console.log("Adding translation for " + language);
    translations[language] = translation;
}

function deCapitalizeFirstLetter(input) {
    if(input.length === 0){
        return '';
    }
    return input.charAt(0).toLowerCase() + input.slice(1);
}

function capitalizeFirstLetter(input) {
    if(input.length === 0){
        return '';
    }
    return input.charAt(0).toUpperCase() + input.slice(1);
}

/**
 * Translate a given source string into the target language. The target
 * language is retrieved from the [[Session]]
 * @param source the original English string
 * @returns string the translation if it's available, otherwise it returns
 *                 the original string
 */
export function _(source, context={}) {
    let language = Session.getLanguage();
    let translation;
    if(language === 'en'){
        translation = source;
    } else {
        if(
            translations.hasOwnProperty(language) &&
            translations[language].hasOwnProperty(source)
        ){
            translation = translations[language][source];
        } else {

            let decap = deCapitalizeFirstLetter(source);

            if(
                translations.hasOwnProperty(language) && 
                translations[language].hasOwnProperty(decap)
            ){
                translation = capitalizeFirstLetter(translations[language][decap]);
            } else { 
                log(`Translation for "${source}" not found, for language ${language}`);
                translation = source;
            }
        }
    }
    if (Object.keys(context).length) {
        translation = Gui.renderString(translation, context);
    }
    return translation;
}

