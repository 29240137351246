/**
 * Register view module.
 * @module register
 */

"use strict";

import * as Constants from '../core/constants.js';
import * as Gui from '../core/gui.js';
import * as Session from '../core/session.js';
import {debugAlert, parseJson, sanitizePhoneNumber, createNewEvent} from '../core/helpers.js';
import * as Middleware from '../core/middleware.js';

export class RegisterView {
    constructor() {
        this.viewName = 'RegisterView';
    }

    render(event, args) {
        let context = {
            'countryCodes': Constants.COUNTRY_CODES,
            'selectedCountry': '+31',
            'backButton': true,
        };

        if(window.formData) {
            if(window.formData.hasOwnProperty('phone')) {
                context.phoneNumber = window.formData.phone;
            }

            if(window.formData.hasOwnProperty('email')) {
                context.email = window.formData.email.trim();
            }

            if(window.formData.hasOwnProperty('email-repeat')) {
                context.emailRepeat = window.formData['email-repeat'].trim();
            }

            if(window.formData.hasOwnProperty('country')) {
                context.selectedCountry = window.formData.country;
            }

            if(window.formData.hasOwnProperty('agreed')) {
                context.agreed = window.formData.agreed;
            }
        } else {
            if(Session.getVar('phoneNumber')){
                context.phoneNumber = Session.getVar('phoneNumber');
            }

            if(Session.getVar('email')){
                context.email = Session.getVar('email').trim();
                context.emailRepeat = Session.getVar('email').trim();
            }
        }

        Gui.render('register.html', context);

        Session.refreshLocationPermissions(() => {
        }, () => {
            Middleware.setupLocationPermissions().then(() => {
                Session.setVar('locationAccess', true);
            }).catch(error => {
                Middleware.getLocationPermissions().then(() => {
                    Session.setVar('locationAccess', true);
                }).catch((err) => {
                    Session.setVar('locationAccess', false);
                });
            });
        });
    }

    /**
     * When someone wants to register again we first try
     * to check whether the current credentials are
     * valid. If that's the case we try to install the
     * wifi profile and try to connet. Only if that
     * fails we redirect the user to the register
     * screen.
     */
    registerAgain(event, args) {
        if(Session.getVar('hasProfile')) {
            Middleware.accountCheck().then(() => {
                window.visit('/#/install-again');
            }).catch((error) => {
                window.visit('/#/register');
            });
        } else {
            window.visit('/#/register');
        }
    }

    submit(event, args) {
        let errors = [];

        if($('input[name=phone]').length === 0){
            return window.visit('/#/register');
        }

        let phoneNumber = $('input[name=phone').val() || '';
        let countryCode = $('select[name=country').val() || '+31';
        let email = ($('input[name=email]').val() || '').toLowerCase().trim();
        let emailRepeat = ($('input[name=email-repeat]').val() || '').toLowerCase().trim();
        let agreed = $('input[name=agree-terms]').is(':checked');

        let validPhone = !isNaN(phoneNumber);

        let phoneError = false;
        let emailError = false;
        let agreeError = false;

        if(!validPhone || (countryCode === '+31' && phoneNumber.length < 9)){
            errors.push(_('Please check your phone number'));
            phoneError = true;
        } else {
            phoneNumber = sanitizePhoneNumber(countryCode, phoneNumber);
        }

        if(email !== emailRepeat){
            errors.push(_('The e-mail addresses are not the same'));
            emailError = true;
        }

        if(email.indexOf('@') === -1 || email.indexOf('.') === -1){
            errors.push(_('The e-mail address is invalid'));
            emailError = true;
        }

        if(!agreed){
            errors.push(_('You need to agree with the terms and conditions'));
            agreeError = true;
        }

        let context = {
            'errors': [],
            'phoneNumber': phoneNumber,
            'email': email,
            'emailRepeat': emailRepeat,
            'agreed': agreed,
            'agreeError': agreeError,
            'phoneError': phoneError,
            'emailError': emailError,
            'countryCodes': Constants.COUNTRY_CODES,
            'selectedCountry': countryCode,
            'backButton': true,
        };

        if(errors.length > 0){
            context.errors = errors;
            Gui.render('register.html', context);
        } else {
            Session.setVar('phoneNumber', phoneNumber);
            Session.setVar('email', email);
            Session.setVar('agreed', agreed);
            Session.setVar('countryCode', countryCode);
            let fullPhoneNumber = countryCode + phoneNumber;
            Session.setVar('fullPhoneNumber', fullPhoneNumber);

            Gui.render('loading.html');
            Middleware.requestPincode(fullPhoneNumber, email).then(result => {
                window.visit('/#/register/pincode');
            }).catch((error) => {
                let errorType = error.type;
                let errorBody = error.body;

                if(errorType === Constants.ERROR_API_NOT_AVAILABLE){
                    window.visit('/#/api-error');
                } else if(errorType === Constants.ERROR_ACCOUNT_ALREADY_EXISTS){
                    errors.push(Gui.safeString(_('There already exists a publicroam account for this phone number. <a href="/#/login-existing">I already have an account</a>.')));
                    context.errors = errors;
                    Gui.render('register.html', context);
                } else if(errorType === Constants.ERROR_ACCOUNT_CREATION_FAILED) {
                    errors.push(_('We could not send the verification code, please check your data.'));
                    if(
                        errorBody !== false &&
                        errorBody.hasOwnProperty('message') &&
                        errorBody.message.hasOwnProperty('phone_number') &&
                        errorBody.message.phone_number.length > 0 &&
                        errorBody.message.phone_number[0] === 'The phone number cant be formatted'
                    ) {
                        errors.push(_('Please check your phone number'));
                        context.phoneError = true;
                    }

                    context.errors = errors;
                    Gui.render('register.html', context);
                } else {
                    window.visit('/#/api-error');
                }
            });
        }
    }

    pinTimeoutActive() {
        const pinTimeout = Session.getVar('pinTimeout') === true;
        if(!pinTimeout) {
            return false;
        }

        const pinTimeoutUntil = Session.getVar('pinTimeoutUntil');
        if(!pinTimeoutUntil) {
            return false;
        }

        if(Date.now() > pinTimeoutUntil) {
            Session.setVar('pinTimeout', false);
            Session.setVar('pinTimeoutUntil', 0);
            return false;
        }
        return true;
    }

    startPincodeTimer() {
        const timeoutActive = Session.getVar('pinTimeout');
        const timeoutUntil = Session.getVar('pinTimeoutUntil');

        if(!timeoutActive || !timeoutUntil || timeoutUntil < Date.now()) {
            return;
        }

        $('.show-when-timeout').fadeIn();

        if(window.pincodeTimerId) {
            window.clearInterval(window.pincodeTimerId);
        }

        window.pincodeTimerId = window.setInterval(() => {
            if(Date.now() > timeoutUntil) {
                window.clearInterval(window.pincodeTimerId);
                window.pincodeTimerId = false;
                $('.show-when-timeout').hide();
                return;
            }
            let timeLeft = Math.floor((timeoutUntil - Date.now()) / 1000);
            if(timeLeft > 1) {
                $('.timeout-seconds').html(timeLeft + ' ' + _('seconds'));
            } else {
                $('.timeout-seconds').html(timeLeft + ' ' + _('second'));
            }
        }, 1000);
    }

    redirectToPincode() {
        return window.visit(
            '/#/register/pincode',
            createNewEvent(''),
            {origin: 'another'}
        );
    }

    anotherPincode(event, args) {
        if(this.pinTimeoutActive()) {
            return this.redirectToPincode();
        }

        Gui.render('loading.html');
        const countryCode = Session.getVar('countryCode');
        const phoneNumber = Session.getVar('phoneNumber');
        let fullPhoneNumber = countryCode + phoneNumber;
        Session.setVar('fullPhoneNumber', fullPhoneNumber);
        Middleware.requestPincode(
            fullPhoneNumber,
            Session.getVar('email')
        ).then(result => {
            Session.setVar('pinTimeout', true);
            Session.setVar('pinTimeoutUntil', Date.now() + 60000);
            this.startPincodeTimer();
            return this.redirectToPincode();
        }).catch((error) => {
            if(error.type === 'ERROR_ACCOUNT_ALREADY_EXISTS') {
                Middleware.addDevice(fullPhoneNumber).then(result => {
                    return this.redirectToPincode();
                }).catch((error) => {
                    return this.redirectToPincode();
                });
            } else {
                return this.redirectToPincode();
            }
        });
    }

    pincode(event, args, extraArgs={}) {
        let timeoutActive = Session.getVar('pinTimeout');
        const timeoutUntil = Session.getVar('pinTimeoutUntil');

        if(!timeoutActive) {
            Session.setVar('pinTimeout', true);
            Session.setVar('pinTimeoutUntil', Date.now() + 60000);
        }

        this.startPincodeTimer();
        let pinTimeoutSecondsLeft = 0;
        timeoutActive = this.pinTimeoutActive();

        if(timeoutActive) {
            pinTimeoutSecondsLeft = Math.floor(
                (Session.getVar('pinTimeoutUntil') - Date.now()) / 1000
            );
            if(!window.pincodeTimerId) {
                this.startPincodeTimer();
            }
        }

        Gui.render('pincode.html', {
            'phoneNumber': Session.getVar('phoneNumber'),
            'countryCode': Session.getVar('countryCode'),
            'backButton': true,
            'pinTimeoutActive': this.pinTimeoutActive(),
            'pintTimeoutSecondsLeft': pinTimeoutSecondsLeft,
        });

        if(pinTimeoutSecondsLeft > 1) {
            $('.timeout-seconds').html(pinTimeoutSecondsLeft + ' ' + _('seconds'));
        } else {
            $('.timeout-seconds').html(pinTimeoutSecondsLeft + ' ' + _('second'));
        }

        if(timeoutActive) {
            $('.show-when-timeout').show();
        } else {
            $('.show-when-timeout').hide();
        }

        // If we're redirected from the pincode/another page, we don't want to
        // automatically focus the pincode input
        if(
            !extraArgs ||
            (typeof extraArgs !== 'object') ||
            !extraArgs.hasOwnProperty('origin') ||
            extraArgs.origin !== 'another'
        ) {
            $('input[name=pincode]').focus();
        }

        if(window.debug && (!Session.getVar('useRealApi'))) {
            debugAlert('Juiste verificatiecode voor testen is 123456');
        }
    }

    pincodeSubmit(event, args) {
        let context = {
            'phoneNumber': Session.getVar('phoneNumber'),
            'countryCode': Session.getVar('countryCode'),
            'backButton': true,
        };

        let pincode = $('input[name=pincode]').val();

        if(pincode === ''){
            context.errors = [_('The verification code is incorrect')];
            return Gui.render('pincode.html', context);
        }

        Gui.render('loading.html');
        Middleware.verifyPincode(
            Session.getVar('countryCode') + Session.getVar('phoneNumber'), pincode
        ).then(result => {
            result = parseJson(result);
            if(
                result !== false &&
                result.hasOwnProperty('username') &&
                result.hasOwnProperty('password') &&
                result.hasOwnProperty('token')
            ) {
                Session.setVar('username', result.username);
                Session.setVar('password', result.password);
                if(result.hasOwnProperty('email')){
                    Session.setVar('email', result.email);
                }
                Session.setVar('token', result.token);
                Session.setVar('hasAccount', true);
                Middleware.sendDeviceInfo().then(()=>{}).catch((e)=>{});
                window.visit('/#/installing');
            } else {
                context.errors = [_('We could not retrieve your account information. Please try again later')];
            }
        }).catch(error => {
            if(error && error.hasOwnProperty('type') && error.type !== Constants.ERROR_ACCOUNT_NOT_FOUND) {
                context.errors = [_('The account could not be found')];
            } else {
                context.errors = [_('The verification code is incorrect')];
            }
            return Gui.render('pincode.html', context);
        });
    }
}
