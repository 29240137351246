/**
 * Connect view module.
 * @module connect
 */

"use strict";

import * as Gui from '../core/gui.js';
import * as Session from '../core/session.js';
import * as Middleware from '../core/middleware.js';
import * as Watcher from '../core/watcher.js';
import * as Helpers from '../core/helpers.js';

export class ConnectView {
    constructor() {
        this.viewName = 'StaticView';
    }

    render(event, args) {
        Gui.render('connect.html');

        Middleware.connect().then(function(){
            Session.setConnected(true);
            window.visit('/#/connected');
        }).catch(function(){
            Session.setConnected(false);
            window.visit('/#/not-connected');
        });
    }

    installAgain(event, args) {
        this.installing(false, [], true);
    }

    otherWifi(event, args) {
        Gui.render('other-wifi.html');
    }

    /**
     * @param {Event} event - the DOM event
     * @param {array} args - a list of arguments from for example a form
     * @param {boolean} again - whether this is a retry. In that case after
     *                          unsuccesfull installing we return the user
     *                          to the register screen.
     */
    installing(event, args, again=false) {
        Gui.render('installing.html');

        let preSetup = new Promise((resolve, reject) => {
            if(!Helpers.isAndroid10()){
                return resolve();
            }

            Middleware.publicroamNearby().then(() => {
                Middleware.getCurrentSsid().then(ssid => {
                    if(ssid !== '<unknown ssid>' && ssid !== '"publicroam"'){
                        return window.visit('/#/other-wifi'); 
                    } else {
                        return resolve();
                    }
                }).catch(() => {
                    return resolve();
                });
            }).catch(() => {
                return reject();
            });
        });

        preSetup.then(() => {
            Middleware.setupProfile().then(() => {
                Session.setVar('hasProfile', true);
                Watcher.stopWatching();
                // When android, use Middleware.connect
                Middleware.connect().then(function(){
                    Session.setConnected(true);
                    Watcher.continueWatching();
                    return window.visit('/#/install-success-connected');
                }).catch((err) => {
                    let starttime = Date.now();
                    console.log(err);

                    let connectionLoop = () => {
                        Middleware.isConnected().then(() => {
                            window.setTimeout(() => {
                                Session.setConnected(true);
                                Watcher.continueWatching();
                                Gui.refreshConnectionStatus();
                                return window.visit('/#/install-success-connected');
                            }, 2000);
                        }).catch(() => {
                            if(Date.now() >= starttime + 10000) {
                                Watcher.continueWatching();
                                Session.setConnected(false);
                                Gui.refreshConnectionStatus();
                                if(Helpers.isAndroid10()) {
                                    return window.visit('/#/install-final-step');
                                } else {
                                    return window.visit('/#/install-success-not-connected');
                                }
                            } else {
                                window.setTimeout(connectionLoop, 1000);
                            }
                        }); 
                    };
                    
                    connectionLoop();
                });
            }).catch((error) => {
                if(again) {
                    return window.visit('/#/register');
                } else {
                    Session.setVar('hasProfile', false);
                    return window.visit('/#/install-failed');
                }
            });
        }).catch(() => {
            return window.visit('/#/account-active');
        });
    }

    connected(event, args) {
        Gui.render('connected.html');
    }

    notConnected(event, args) {
        if(!Session.getVar('hasProfile')) {
            window.visit('/#/not-connected-no-profile');
        } else {
            Gui.render('not-connected.html');
        }
    }

    notConnectedNoLocation(event, args) {
        if(!Session.getVar('hasProfile')) {
            window.visit('/#/not-connected-no-profile');
        } else {
            Gui.render('not-connected-no-location.html');
        }
    }

    notConnectedNoProfile(event, args) {
        if(Session.isConnected()){
            window.visit('/#/connected');
        } else {
            if(Session.getVar('hasProfile')) {
                window.visit('/#/not-connected');
            } else {
                Gui.render('not-connected-no-profile.html');
            }
        }
    }

    installFailed(event, args) {
        Gui.render('install-failed.html');
    }

    installSuccessConnected(event, args) {
        Gui.render('install-success-connected.html');
    }

    installSuccessNoLocation(event, args) {
        Gui.render('install-success-no-location.html');
    }

    installSuccessNotConnected(event, args) {
        Session.refreshLocationPermissions(() => {
            Gui.render('install-success-not-connected.html');
        }, () => {
            return window.visit('/#/install-success-no-location');
        });
    }

    installFinalStep(event, args) {
        Gui.render('install-final-step.html');
    }

    accountActive(event, args) {
        Gui.render('account-active.html');
    }
}
