/**
 * Login view module.
 * @module login
 */

"use strict";

import * as Constants from '../core/constants.js';
import * as Gui from '../core/gui.js';
import * as Session from '../core/session.js';
import {debugAlert, parseJson, sanitizePhoneNumber} from '../core/helpers.js';
import * as Middleware from '../core/middleware.js';

export class LoginView {
    constructor() {
        this.viewName = 'RegisterView';
    }

    render(event, args) {
        let context = {
            'countryCodes': Constants.COUNTRY_CODES,
            'selectedCountry': '+31',
            'backButton': true,
        };

        if(Session.getVar('phoneNumber')){
            context.phoneNumber = Session.getVar('phoneNumber');
        }
        if(Session.getVar('countryCode')) {
            context.selectedCountry = Session.getVar('countryCode');
        }
        Gui.render('login-existing.html', context);
    }

    submit(event, args) {
        let errors = [];

        if($('input[name=phone]').length === 0){
            return window.visit('/#/register');
        }

        let phoneNumber = $('input[name=phone').val() || '';
        let countryCode = $('select[name=country').val() || '+31';

        let validPhone = !isNaN(phoneNumber);

        let phoneError = false;

        if(!validPhone || (countryCode === '+31' && phoneNumber.length < 9)){
            errors.push(_('Please check your phone number'));
            phoneError = true;
        } else {
            phoneNumber = sanitizePhoneNumber(countryCode, phoneNumber);
        }

        let context = {
            'errors': [],
            'phoneNumber': phoneNumber,
            'phoneError': phoneError,
            'countryCodes': Constants.COUNTRY_CODES,
            'selectedCountry': countryCode,
            'backButton': true,
        };

        if(errors.length > 0){
            context.errors = errors;
            Gui.render('login-existing.html', context);
        } else {
            Session.setVar('phoneNumber', phoneNumber);
            Session.setVar('countryCode', countryCode);
            let fullPhoneNumber = countryCode + phoneNumber;

            if(window.debug) {
                Session.dumpVars();
            }

            Middleware.addDevice(fullPhoneNumber).then(result => {
                window.visit('/#/register/pincode');
            }).catch((error) => {
                let errorType = error.type;
                let errorBody = error.body;

                if(errorType === Constants.ERROR_API_NOT_AVAILABLE){
                    window.visit('/#/api-error');
                } else if(errorType === Constants.ERROR_ACCOUNT_NOT_FOUND){
                    errors.push(_('No account found. Check the number. Or go <a href="/#/register">back</a>'));
                    context.errors = errors;
                    Gui.render('login-existing.html', context);
                } else {
                    window.visit('/#/api-error');
                }
            });
        }
    }


    pincode(event, args) {
        Gui.render('pincode.html');
        $('input[name=pincode]').focus();

        if(window.debug && (!Session.getVar('useRealApi') || !Session.getVar('useRealApi'))) {
            debugAlert('Juiste verificatiecode voor testen is 123456');
        }
    }

    pincodeSubmit(event, args) {
        let context = {
            'phoneNumber': Session.getVar('phoneNumber'),
            'countryCode': Session.getVar('countryCode'),
            'backButton': true,
        };

        let pincode = $('input[name=pincode]').val();

        if(pincode === ''){
            context.errors = [_('The verification code is incorrect')];
            return Gui.render('pincode.html', context);
        }

        Middleware.verifyPincode(
            Session.getVar('countryCode') + Session.getVar('phoneNumber'), pincode
        ).then(result => {
            result = parseJson(result);
            if(
                result !== false &&
                result.hasOwnProperty('username') &&
                result.hasOwnProperty('password') &&
                result.hasOwnProperty('token')
            ) {
                Session.setVar('username', result.username);
                Session.setVar('password', result.password);
                Session.setVar('token', result.token);
                Session.setVar('hasAccount', true);
                window.visit('/#/installing');
            } else {
                context.errors = [_('We could not retrieve your account information. Please try again later')];
            }
        }).catch(error => {
            let errorType = error.type;
            let errorBody = error.body;

            if(errorType !== Constants.ERROR_ACCOUNT_NOT_FOUND) {
                context.errors = [_('The account could not be found')];
            } else {
                context.errors = [_('The verification code is incorrect')];
            }
            return Gui.render('pincode.html', context);
        });
    }
}
